<template>
    <!-- 为 ECharts 准备一个具备大小（宽高）的 DOM -->
    <div class="chartsWrapper">
        <div class="chartsOverview">

            <div class="chart">

                <div class="detailTitle">
                    <h3>净资产 最近30天</h3>
                </div>

                <div ref="networth" id="networth" @click="gonetworth"></div>
            </div>

            <div class="chart" @click="goRP">

                <div class="detailTitle" >
                    <h3>退休计划</h3>
                </div>

                <div class="retirementPlan" ref="retirementPlan">
                    <div class="rp-detail">
                        <li v-for="(detail,index) in retirement[0].details" :key="index">
                            <a href="#">
                                <div>
                                    <h3 class="name">{{ detail.label }}</h3>
                                    <p class="money">{{ detail.amount | formatPrice }}</p>
                                </div>
                                <span :class="`${detail.name}`"></span>
                            </a>
                        </li>
                    </div>
                </div>

            </div>

            <div class="chart">

                <div class="detailTitle">
                    <h3>收入 vs 支出</h3>
                </div>

                <div id="ie" ref="ie" @click="goie"></div>
            </div>

            <div class="chart">

                <div class="detailTitle">
                    <h3>资产分配</h3>
                </div>

                <div ref="allo" id="allo"></div>
            </div>

            <div class="chart" @click="goInvestment">

                <div class="detailTitle">
                    <h3>投资收益</h3>
                </div>

                <!--                <div ref="investment" id="investment"></div>-->

                <div class="investmentDetail">
                    <ul class="investmentDetailSub" v-for="(item,index) in investmentArray"
                        :key="index">
                        <span class="date">{{ item.settleDate }}</span>
                        <span id="money"
                              :class="{'positive-number': item.dailyProfitTotal >= 0, 'negative-number': item.dailyProfitTotal < 0}">{{
                                item.dailyProfitTotal | formatPrice
                            }}</span>

                        <ul class="detailInv" v-for="(detail,index) in item.details"
                            :key="index" v-bind:style="{ backgroundColor: detail.amount ? '#F1F6F9' : '#FFFBEC' }">
                            <p class="earn" v-if="detail.amount >= 0">盈利</p>
                            <p class="lose" v-if="detail.amount < 0">亏损</p>
                            <span class="amountIns">{{ detail.amount | formatPrice }}</span>
                            <span class="categoryIns">{{ detail.category }}</span>
                        </ul>
                    </ul>
                </div>

            </div>


        </div>
    </div>

</template>


<script>
import {chartie} from "@/api/overview"
import {getMyLifePlan, getMyLifePlansSetting, hasOfficialPlan} from "@/api/retireplan";
import {Enum} from "@/utils/enums";
import {last7Days} from "@/api/investment";


export default {


    data() {
        return {
            investmentArray: [{
                dailyProfitTotal: 10000,
                settleDate: '2024-01-01',
                details: [{
                    category: '投资',
                    amount: 10000
                },
                ]
            },],

            planId: '',
            goalCategoryEnum: new Enum()
                .add('ANNUAL_SAVINGS', '退休前每年存款', 1)
                .add('SOCIAL_SECURITY_INCOME', '养老保险', 2)
                .add('RENTAL_INCOME', '房屋出租', 3)
                .add('WORK_INCOME', '退休后工作', 4)
                .add('PROPERTY_SALE', '变卖资产', 5)
                .add('OTHER_INCOME', '其他收入', 6)
                .add('RETIREMENT_SPENDING', '退休支出', 10)
                .add('LIFE_EXPENSE', '生活开支', 11)
                .add('PARENTS_CARE', '赡养父母', 12)
                .add('KIDS_CARE', '抚养小孩', 13)
                .add('HEALTH_CARE', '医疗', 14)
                .add('HOME_PURCHASE', '房贷', 15)
                .add('OTHER_EXPENSE', '其他支出', 16),

            retirement: [{
                categoryLabel: '净值',
                categoryName: 'NETWORTH',
                details: [{
                    name: 'today',
                    label: '今日净值',
                    amount: '0',
                }, {
                    name: 'atRetirement',
                    label: '退休时净值',
                    amount: '0',
                    // retirementAge: '',
                }, {
                    name: 'ANNUAL_SAVINGS',
                    label: '退休前每年存款',
                    amount: '0',
                }, {
                    name: 'RETIREMENT_SPENDING',
                    label: '退休后每月支出',
                    amount: '0',
                }],

            }, {
                categoryLabel: '退休收入',
                categoryName: 'incomeRetirement',
                details: [{
                    name: '',
                    label: '养老保险',
                    amount: '0',
                },]
            }, {
                categoryLabel: '退休支出',
                categoryName: 'expenseRetirement',
                details: [{
                    name: '',
                    label: '退休后支出',
                    amount: '0',
                },]
            },
            ],

            option: {
                title: {
                    text: '退休计划',
                    top: '3%',
                    left: '3%',

                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {

                            // backgroundColor: '#1047F7'
                            backgroundColor: '#557bdd'
                        }
                    }
                },
                legend: {
                    top: '13%',
                    right: '4%',
                    data: ['中位数', '坏情况']
                },
                grid: {
                    top: '25%',
                    left: '3%',
                    right: '4%',
                    bottom: '10%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        // splitLine: {
                        //     show: false,
                        // },
                    }
                ],
                series: [
                    {
                        name: '中位数',
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        lineStyle: {
                            width: 0
                        },
                        showSymbol: false,
                        areaStyle: {
                            opacity: 1,
                            // color: '#6C9BF3',
                            color: '#557bdd',
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        endLabel: {
                            show: true,
                            align: 'right',
                            verticalAlign: 'bottom',
                        },
                        data: [120, 132, 101, 134, 90, 230, 210]
                    },
                    {
                        name: '坏情况',
                        type: 'line',
                        stack: 'Total',
                        smooth: true,

                        lineStyle: {
                            width: 0
                        },
                        showSymbol: false,
                        areaStyle: {
                            opacity: 1,
                            color: '#91cc75',
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        endLabel: {
                            show: true,
                            align: 'right',
                            verticalAlign: 'bottom',
                        },
                        data: [220, 182, 191, 234, 290, 330, 310]
                    },
                ]
            }, netWorth: {
                // title: {
                //     text: '净资产 最近30天',
                //     top: '3%',
                //     left: '1%',
                //     textStyle: {
                //         color: '#353535',
                //         fontSize: 16,
                //     }
                // },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#557bdd',

                        }
                    }
                },
                grid: {
                    top: '10%',
                    left: '4%',
                    right: '4%',
                    bottom: '6%',
                    containLabel: true
                },
                xAxis: {
                    // show: false,
                    type: 'category',
                    boundaryGap: false,
                    data: ['1', '2', '3', '4', '5', '6', '7', '8', '9']
                },
                yAxis: {
                    type: 'value',
                    min: 0,
                    // axisLine:{
                    //     show:true
                    // },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed',
                            color: '#F0F1F3'
                        }
                    },
                    // name: "单位（万元）",
                    nameTextStyle: {
                        color: "#aaa",
                        // nameLocation: "end",
                        // left: '6%'
                    },
                    // axisLabel: {
                    //     //这种做法就是在y轴的数据的值旁边拼接单位，貌似也挺方便的
                    //     // formatter: "{value}/10000 万元",
                    //     formatter: function (params) {
                    //         if (params == 0) {
                    //             return ''
                    //         } else {
                    //             return params/10000
                    //         }
                    //     }
                    // },
                },
                series: [{
                    data: [820, 932, 901, 934, 1290, 1330, 1320, 11, 22],
                    type: 'line',
                    smooth: true,
                    lineStyle: {
                        width: 0
                    },
                    // endLabel: {
                    //     show: true,
                    //     align: 'right',
                    //     verticalAlign: 'bottom',
                    // },
                    showSymbol: false,
                    areaStyle: {normal: {}},
                    itemStyle: {
                        normal: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0, color: "rgba(44,127,255,0.4)" // 0% 处的颜色
                                }, {
                                    offset: 1, color: "rgba(44,127,255,0.05)" // 100% 处的颜色
                                }]
                            ), //背景渐变色
                            lineStyle: {
                                width: 1,
                                type: 'solid',
                                color: "#2C7FFF"
                            }
                        }
                    }

                }]
            }, incomeExpense: {
                // title: {
                //     text: '收入 vs 支出',
                //     top: '3%',
                //     left: '1%',
                //     textStyle: {
                //         color: '#353535',
                //         fontSize: 16,
                //     }
                // },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    top: '0%',
                    right: '2%',
                    data: ['收入', '支出'],
                    show: true,
                },
                grid: {
                    containLabel: true,
                    top: '10%',
                    left: '2%',
                    right: '2%',
                    bottom: '2%',
                },
                calculable: true,
                xAxis: [
                    {
                        type: 'category',
                        data: ['',],
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'dashed',
                                color: '#F0F1F3'
                            }
                        },
                    }
                ],
                series: [
                    {
                        name: '收入',
                        type: 'bar',
                        data: [0,],
                        label: {
                            show: false,
                            position: 'top',
                            formatter: function (params) {
                                if (params.value == 0) {
                                    return ''
                                } else {
                                    return params.value
                                }
                            }
                        },
                        barWidth: 15,   //柱状宽度
                        itemStyle: {    //柱状颜色和圆角
                            color: '#2c7fff',
                            // color: '#ee6666',
                            barBorderRadius: [4, 4, 0, 0], // （顺时针左上，右上，右下，左下）
                        },
                    },
                    {
                        name: '支出',
                        type: 'bar',
                        data: [
                            2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
                        ],
                        label: {
                            show: false,
                            position: 'top',
                            formatter: function (params) {
                                if (params.value == 0) {
                                    return ''
                                } else {
                                    return Math.floor(params.value)
                                }
                            }
                        },
                        barWidth: 15,   //柱状宽度
                        itemStyle: {    //柱状颜色和圆角
                            // color: '#92DFD1',
                            color: '#FED023',
                            barBorderRadius: [4, 4, 0, 0], // （顺时针左上，右上，右下，左下）
                        },
                    }
                ]
            }, allocation: {
                // title: {
                //     text: '资产分配',
                //     top: '3%',
                //     left: '3%',
                //     textStyle: {
                //         color: '#353535',
                //         fontSize: 16,
                //     }
                // },
                tooltip: {
                    trigger: 'item'
                },
                // legend: {
                //     top: 'center',
                //     right: '20%',
                //     textStyle: {color: 'black'},
                //     orient: 'vertical',
                // },
                color: ['#2796ff', '#FED023', '#33C5FF', '#4FC1D9', '#43D7B5', '#0091FC', '#184589', '#FD6862', '#4E7AF5'],
                series: [
                    {
                        type: 'pie',
                        radius: ['50%', '60%'],
                        center: ['50%', '50%'],
                        // avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 4,
                            borderColor: '#fff',
                            borderWidth: 1
                        },
                        // label: {
                        //     show: true,
                        //     position: 'center'
                        // },
                        data: [
                            {value: 40, name: 'rose 1'},
                            {value: 38, name: 'rose 2'},
                        ],

                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 30,
                                fontWeight: 'bold'
                            },
                        },
                        // labelLine: {
                        //     show: true
                        // },
                    }
                ]
            },
            investment: {
                // title: {
                //     text: '投资收益',
                //     top: '3%',
                //     left: '1%',
                //     textStyle: {
                //         color: '#353535',
                //         fontSize: 16,
                //     }
                // },
                tooltip: {
                    trigger: 'axis'
                },
                // legend: {
                //     top: '13%',
                //     right: '2%',
                //     data: ['投资']
                // },
                grid: {
                    containLabel: true,
                    top: '2%',
                    left: '6%',
                    right: '6%',
                    bottom: '10%',
                },
                calculable: true,
                xAxis: [
                    {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri',],
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisTick: {
                            show: false
                        },
                        // splitLine: {
                        //     show: false
                        // },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'dashed',
                                color: '#F0F1F3'
                            }
                        },
                    }
                ],
                series: [
                    {
                        name: '投资',
                        type: 'bar',
                        data: [1000, 2000, -3000, 5000, 2000],
                        label: {
                            show: false,
                            position: 'top',
                            formatter: function (params) {
                                if (params.value == 0) {
                                    return ''
                                } else {
                                    return params.value
                                }
                            }
                        },
                        barWidth: 15,   //柱状宽度
                        itemStyle: {    //柱状颜色和圆角
                            // color: '#D786C0',
                            // color: '#557bdd',
                            // barBorderRadius: [6, 6, 0, 0], // （顺时针左上，右上，右下，左下）
                            color: (val) => {
                                var index_color = val.value;
                                return index_color > 0 ? "#4771d6" : "#FEC760";
                            },
                            barBorderRadius: (val) => {
                                var index_ = val.value;
                                return index_ > 0 ? [6, 6, 0, 0] : [0, 0, 6, 6];
                            },
                        },
                    },
                ]
            },
        };
    },

    mounted() {
        // debugger;
        this.updateIE();
        this.updateAllocation();
        this.updateNetWorth();
        this.updateRP();
        // this.updateInvestment();
        this.updateInvestmentList();
    },

    computed: {
        chartData() {
            return this.$store.state.chartData;
        },
        tableData() {
            return this.$store.state.tableData;
        },
        dynamicImagePath(name) {
            console.log(name)
            // if (name) {
            //     return this.image1;
            // } else {
            //     return this.image2;
            // }
        }
    },

    watch: {
        chartData() {
            this.updateNetWorth();
        },
        tableData() {
            this.updateAllocation();
        }
    },

    methods: {

        updateRP() {
            hasOfficialPlan().then(resp => {
                // console.log("hasOfficialPlan", resp.data);

                if (resp.data === true) {
                    getMyLifePlansSetting().then(resp => {
                        this.planId = resp.data[0].planId;
                        getMyLifePlan(this.planId).then(resp => {
                            // var incomeGoal = [];
                            // var expenseGoal = [];
                            resp.data.goals.forEach(goal => {
                                if (goal.goalCategory === "ANNUAL_SAVINGS") {
                                    // incomeGoal.push({
                                    //     name: goal.goalCategory,
                                    //     label: this.goalCategoryEnum.getLabelByField(goal.goalCategory),
                                    //     amount: goal.amount,
                                    // });
                                    this.retirement[0].details[2].amount = goal.amount;

                                } else if (goal.goalCategory === "RETIREMENT_SPENDING") {
                                    // expenseGoal.push({
                                    //     name: goal.goalCategory,
                                    //     label: this.goalCategoryEnum.getLabelByField(goal.goalCategory),
                                    //     amount: goal.amount,
                                    // });
                                    this.retirement[0].details[3].amount = goal.amount;
                                }
                            })
                            // this.retirement[1].details = incomeGoal;
                            // this.retirement[2].details = expenseGoal;

                            // 设置退休计划里的退休时净值
                            var retirementAge = resp.data.planProfile.retirementAge;
                            this.retirement[0].details[1].label = retirementAge + '岁退休净值';
                            // console.log("this.retirement[0].details[1].label", this.retirement[0].details[1].label);

                            resp.data.cashFlowDetail.forEach(cashFlow => {
                                if (cashFlow.age === retirementAge) {
                                    this.retirement[0].details[1].amount = cashFlow.totalFunds;
                                }
                            })

                        });

                    });
                }
            });
        },

        updateIE() {

            var myIE = this.$echarts.init(this.$refs.ie);
            chartie().then((resp) => {

                // console.log(resp)
                var data = resp.data;
                var xAxisData = [];
                var seriesDataIncome = [];
                var seriesDataExpense = [];
                data.forEach(ele => {
                    xAxisData.push(ele.month)
                    seriesDataIncome.push(ele.income)
                    seriesDataExpense.push(ele.expense)
                })

                this.incomeExpense.xAxis[0].data = xAxisData;
                this.incomeExpense.series[0].data = seriesDataIncome;
                this.incomeExpense.series[1].data = seriesDataExpense;

                myIE.setOption(this.incomeExpense);
            });
        },

        updateNetWorth() {

            var myNetWorth = this.$echarts.init(this.$refs.networth);
            var data = this.$store.state.chartData;
            // debugger;
            var xAxisData = [];
            var seriesData = [];
            data.forEach(ele => {
                xAxisData.push(ele.day)
                seriesData.push(ele.netAsset)
            })

            // 设置退休计划里的今日净值
            this.retirement[0].details[0].amount = seriesData[29];

            this.netWorth.xAxis.data = xAxisData;
            this.netWorth.series[0].data = seriesData;
            myNetWorth.setOption(this.netWorth);
            // // 更新echarts图表
            // const option = {...};
            // this.chartInstance.setOption(option);
            //
            // // 更新表格
            // // ...
        },

        updateAllocation() {

            // console.log("myAllocation", this.$store.state.tableData);

            var myAllocation = this.$echarts.init(this.$refs.allo);

            var data = this.$store.state.tableData;
            var seriesData = [];
            data.forEach(ele => {
                var item = {value: ele.categoryAmount, name: ele.categoryName};
                seriesData.push(item);
            })
            this.allocation.series[0].data = seriesData;
            myAllocation.setOption(this.allocation);
        },

        updateInvestment() {

            var myInvestment = this.$echarts.init(this.$refs.investment);

            last7Days().then(resp => {
                // console.log("xxxxxxxxx---last7Days", resp.data)
                var data = resp.data;
                var seriesData = [];
                var xAxisData = [];
                data.forEach(ele => {
                    seriesData.push(ele.dailyProfitTotal);
                    xAxisData.push(ele.settleDate)
                })
                this.investment.series[0].data = seriesData;
                this.investment.xAxis[0].data = xAxisData;
                myInvestment.setOption(this.investment);

            });


        },

        updateInvestmentList() {
            last7Days().then(resp => {
                var data = resp.data;
                var tmp = [];
                var tmpDetails = [];
                data.forEach(ele => {
                    tmpDetails = [{category: '投资', amount: parseInt(ele.dailyProfitTotal)}];
                    tmp.push({
                        dailyProfitTotal: parseInt(ele.dailyProfitTotal),
                        settleDate: ele.settleDate,
                        details: tmpDetails
                    });
                })

                this.investmentArray = tmp.reverse();
            });
        },

        gonetworth() {
            this.$store.commit("SET_isActiveIndex","/product/networth")
            this.$router.push("/product/networth");
        },

        goie() {
            this.$store.commit("SET_isActiveIndex","/product/incomeexpense")
            this.$router.push("/product/incomeexpense");
        },

        goRP() {
            this.$store.commit("SET_isActiveIndex","/product/retireplan")
            this.$router.push("/product/retireplan");
        },

        goInvestment() {
            this.$store.commit("SET_isActiveIndex","/product/investment")
            this.$router.push("/product/investment");
        },
    },

}
</script>

<style scoped>


.chartsWrapper {
    /*width: 1000px;*/
    /*margin: 0 auto;*/
    padding-left: 3%;
    width: 94%;
    height: calc(100% - 40px);
    min-height: 700px;
    /*background-color: blue;*/
    /*overflow: auto;*/
}

.chartsOverview {
    /*width: 1000px;*/
    margin-top: 20px;
    height: calc(100% - 40px);
    display: grid;
    grid-template-rows: repeat(5, 19.5%);
    grid-template-columns: repeat(7, 13.2%);
    grid-template-areas: 'b b b b b e e'
                         'a a a d d e e'
                         'a a a d d e e'
                         'c c c c c e e'
                         'c c c c c e e';
    grid-column-gap: 16px;
    grid-row-gap: 16px;
}

.chartsOverview .chart:nth-child(1) {
    grid-area: a;
    background: white;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding-top: 10px;
    /*padding-left: 10px;*/
}

.chartsOverview .chart:nth-child(2) {
    grid-area: b;
    background: white;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    /*padding: 10px 10px;*/
    padding: 10px 10px 10px 0;
}

.chartsOverview .chart:nth-child(3) {
    grid-area: c;
    background: white;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding-top: 10px;
    /*padding-left: 10px;*/
}

.chartsOverview .chart:nth-child(4) {
    grid-area: d;
    background: white;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding-top: 10px;
    /*padding-left: 10px;*/
}

.chartsOverview .chart:nth-child(5) {
    grid-area: e;
    background: white;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding-top: 10px;
    /*margin-bottom: 20px;*/
}

#ie {
    height: calc(100% - 60px);
}

#allo {
    height: calc(100% - 60px);
}

#investment {
    height: calc(100% - 60px);
}

.investmentDetail {
    padding: 10px 24px;
    height: calc(100% - 60px);
    overflow: auto;
}

.investmentDetail .investmentDetailSub {
    padding-bottom: 10px;
}

.investmentDetail .investmentDetailSub .date {
    font-size: 16px;
    color: var(--font-color);
    font-weight: 600;
}

.investmentDetail .investmentDetailSub #money {
    float: right;
    font-size: 16px;
    font-weight: 400;
}

.investmentDetail .investmentDetailSub .detailInv {
    height: 36px;
    line-height: 36px;
    margin-top: 10px;
    padding: 0 14px;
    display: flex;
}

.investmentDetail .investmentDetailSub .detailInv .earn{
    /*display: block;*/
    width: 24px;
    height: 20px;
    margin: 8px 8px 8px 0;
    padding: 0 6px;
    background: var(--blue-2);
    border-radius: 2px;
    color: white;
    font-size: 12px;
    line-height: 20px;
}

.investmentDetail .investmentDetailSub .detailInv .lose{
    width: 24px;
    height: 20px;
    background: var(--progress-bar-color);
    border-radius: 2px;
    color: white;
    margin: 8px 8px 8px 0;
    padding: 0 6px;
    font-size: 12px;
    line-height: 20px;
}

/*.investmentDetail .investmentDetailSub .detailInv .categoryIns{*/
/*    float: right;*/
/*}*/
.investmentDetail .investmentDetailSub .detailInv .amountIns{
    font-size: 14px;
    font-weight: 400;
    flex: 1;
}

#networth {
    height: calc(100% - 60px);
}

/*资产负债中 每种资产样式标题和总金额样式*/
.detailTitle {
    margin-bottom: 10px;
    padding-left: 30px;
    border-left: 5px solid var(--blue-2);
    height: 30px;
    line-height: 30px;
}

.detailTitle h3 {
    color: var(--header-font-color);
    font-size: 17px;
}

.rp-detail {
    padding-top: 2%;
    height: 240px;
    /*width: 100%;*/
    overflow-y: scroll;
    display: flex;
}


.rp-detail::-webkit-scrollbar {
    display: none;
}

.rp-detail h4 {
    /*color: var(--overview-chart-title-color);*/
    /*color: white;*/

    font-size: 14px;
    height: 14px;
    line-height: 14px;
    padding: 5px 20px 5px 20px;
    /*background: var(--borderline-color);*/
    /*background: var(--blue-2);*/
    background: var(--main-background-color);
    border-radius: 6px;
}


/*资产负债中 每种资产样式*/

/*资产负债中 每种资产中具体细节列表样式*/
.rp-detail li {
    flex: 1;
    /*float: left;*/
    /*width: 23%;*/
    background: linear-gradient(to right, white, #F7F8FD);
    /*margin: 2% 2%;*/
    /*margin-right: .6%;*/
    padding: 8px 0 8px 25px;
    height: 40px;
    /*line-height: 20px;*/
    /*border-bottom: 1px solid var(--borderline-color);*/
    border-radius: 2px;
}

.rp-detail ul li:hover {
    background-color: var(--hover-color);
    /*color: var(--sidebar-color);*/
}

.rp-detail a {
    /*position: relative;*/
    display: flex;
    padding: 0 0.4rem;

}

.rp-detail a div {
    flex: 1;
}

.rp-detail a img {
    display: block;
    position: absolute;
    right: .6rem;
    top: .1rem;
    width: 3rem;
    height: 3rem;
    background: var(--blue-2);
}


/*资产与负债中 每列名称、金额、备注样式*/

.name {
    font-size: 12px;
    /*color: #303133;*/
    color: var(--content-color)
}

.money {
    /*float: right;*/
    font-size: 18px;
    /*color: var(--content-color);*/
    color: var(--h2-color);
}


.today {
    display: block;
    /*position: absolute;*/
    /*right: .6rem;*/
    /*top: .1rem;*/
    width: 3rem;
    height: 3rem;
    /*background: var(--blue-2);*/
    /*background-image: url("@/assets/imgs/today.png");*/
    background-image: url("@/assets/imgs/overviewM.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.atRetirement {
    display: block;
    /*position: absolute;*/
    /*right: .6rem;*/
    /*top: .1rem;*/
    width: 3rem;
    height: 3rem;
    /*background: var(--blue-2);*/
    /*background-image: url("@/assets/imgs/atRetirement.png");*/
    background-image: url("@/assets/imgs/overviewM.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.ANNUAL_SAVINGS {
    display: block;
    /*position: absolute;*/
    /*right: .6rem;*/
    /*top: .1rem;*/
    width: 3rem;
    height: 3rem;
    /*background: var(--blue-2);*/
    /*background-image: url("@/assets/imgs/ANNUAL_SAVINGS.png");*/
    background-image: url("@/assets/imgs/overviewW.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.RENTAL_INCOME {
    display: block;
    /*position: absolute;*/
    /*right: .6rem;*/
    /*top: .1rem;*/
    width: 3rem;
    height: 3rem;
    background: var(--blue-2);
    background-image: url("@/assets/imgs/RENTAL_INCOME.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.RETIREMENT_SPENDING {
    display: block;
    /*position: absolute;*/
    /*right: .6rem;*/
    /*top: .1rem;*/
    width: 3rem;
    height: 3rem;
    /*background: var(--blue-2);*/
    /*background-image: url("@/assets/imgs/RETIREMENT_SPENDING.png");*/
    background-image: url("@/assets/imgs/overviewB.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.OTHER_EXPENSE {
    display: block;
    /*position: absolute;*/
    /*right: .6rem;*/
    /*top: .1rem;*/
    width: 3rem;
    height: 3rem;
    background: var(--blue-2);
    background-image: url("@/assets/imgs/OTHER_EXPENSE.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.PROPERTY_SALE {
    display: block;
    /*position: absolute;*/
    /*right: .6rem;*/
    /*top: .1rem;*/
    width: 3rem;
    height: 3rem;
    background: var(--blue-2);
    background-image: url("@/assets/imgs/PROPERTY_SALE.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/*.add('SOCIAL_SECURITY_INCOME', '养老保险', 2)*/
/*.add('WORK_INCOME', '退休后工作', 4)*/
/*.add('OTHER_INCOME', '其他收入', 6)*/
/*.add('LIFE_EXPENSE', '生活开支', 11)*/
/*.add('PARENTS_CARE', '赡养父母', 12)*/
/*.add('KIDS_CARE', '抚养小孩', 13)*/
/*.add('HEALTH_CARE', '医疗', 14)*/
/*.add('HOME_PURCHASE', '房贷', 15)*/


.SOCIAL_SECURITY_INCOME {
    display: block;
    /*position: absolute;*/
    /*right: .6rem;*/
    /*top: .1rem;*/
    width: 3rem;
    height: 3rem;
    background: var(--blue-2);
    background-image: url("@/assets/imgs/PROPERTY_SALE.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.WORK_INCOME {
    display: block;
    /*position: absolute;*/
    /*right: .6rem;*/
    /*top: .1rem;*/
    width: 3rem;
    height: 3rem;
    background: var(--blue-2);
    background-image: url("@/assets/imgs/PROPERTY_SALE.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.OTHER_INCOME {
    display: block;
    /*position: absolute;*/
    /*right: .6rem;*/
    /*top: .1rem;*/
    width: 3rem;
    height: 3rem;
    background: var(--blue-2);
    background-image: url("@/assets/imgs/PROPERTY_SALE.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.LIFE_EXPENSE {
    display: block;
    /*position: absolute;*/
    /*right: .6rem;*/
    /*top: .1rem;*/
    width: 3rem;
    height: 3rem;
    background: var(--blue-2);
    background-image: url("@/assets/imgs/RETIREMENT_SPENDING.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.PARENTS_CARE {
    display: block;
    /*position: absolute;*/
    /*right: .6rem;*/
    /*top: .1rem;*/
    width: 3rem;
    height: 3rem;
    background: var(--blue-2);
    background-image: url("@/assets/imgs/OTHER_EXPENSE.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.KIDS_CARE {
    display: block;
    /*position: absolute;*/
    /*right: .6rem;*/
    /*top: .1rem;*/
    width: 3rem;
    height: 3rem;
    background: var(--blue-2);
    background-image: url("@/assets/imgs/OTHER_EXPENSE.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.HEALTH_CARE {
    display: block;
    /*position: absolute;*/
    /*right: .6rem;*/
    /*top: .1rem;*/
    width: 3rem;
    height: 3rem;
    background: var(--blue-2);
    background-image: url("@/assets/imgs/RETIREMENT_SPENDING.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.HOME_PURCHASE {
    display: block;
    /*position: absolute;*/
    /*right: .6rem;*/
    /*top: .1rem;*/
    width: 3rem;
    height: 3rem;
    background: var(--blue-2);
    background-image: url("@/assets/imgs/RENTAL_INCOME.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.investmentDetail::-webkit-scrollbar{
    display: none;
}
</style>
