<template>

    <div class="container">
        <div class="sideContainer">
            <SiderAside></SiderAside>
        </div>
        <div class="rightContainer">
            <div class="headContainer">
                <Nav></Nav>
            </div>
            <div class="mainContainer">
                <router-view></router-view>
            </div>
        </div>
    </div>

    <!--        <el-container>
                <el-aside width="auto">
                    <SiderAside></SiderAside>
                </el-aside>
                <el-container>
                    <el-header>
                        <Nav></Nav>
                    </el-header>
                    <el-main>
                        <router-view></router-view>
                    </el-main>
                </el-container>
            </el-container>-->
</template>

<script>
import SiderAside from "@/components/SideAside.vue";
import Nav from "@/components/Nav.vue";

export default {
    data() {
        return {}
    },
    components: {
        SiderAside,
        Nav,
    }
}
</script>

<style>
/*.el-header {
    padding: 0 0;
    z-index: 200;
}

.el-container {
    height: 100vh;
}

.el-main {
    height: calc(100% - 90px);
}*/

.container {
    display: flex;
    /*width: 100vw;*/
    height: 100vh;
    min-width: 1280px;

}

.sideContainer {
    /*width: 368px;*/
    /*overflow: auto;*/
}

.rightContainer {
    flex: 1;
    /*margin-left: 20px;*/
    display: flex;
    flex-direction: column;
}

.rightContainer .headContainer {
    /*height: 81px;*/
    overflow: auto;
}

.rightContainer .mainContainer {
    flex: 1;
    overflow: auto;
}



</style>
