import service from "@/utils/request";

export const selectIncome = (month) => {
    return service.get("/IncomeExpense/income", {params: {month}})
}

export const selectExpense = (month) => {
    return service.get("/IncomeExpense/expense", {params: {month}})
}

export const addIncome = (incomeForm) => {
    return service.post("/IncomeExpense", incomeForm)
}

export const addExpense = (expenseForm) => {
    return service.post("/IncomeExpense", expenseForm)
}

export const showdetail = (balanceId) => {
    return service.get("/balanceOperatedHistory/" + balanceId)
}

export const onDelete = (id) => {
    return service.delete("/IncomeExpense/" + id)
}

export const onEdit = (editForm) => {
    return service.put("/IncomeExpense", editForm)
}


export const importIE = (form) => {
    // return service.post("/IncomeExpense/preImportIncExp", form,30000)
    return service({
        url:"/IncomeExpense/preImportIncExp",
        method: "post",
        data: form,
        timeout: 600000
    });
}

export const relatedIE = (form) => {
    return service.post("/IncomeExpense/importIncExp", form)
}


export const selectCategories = () => {
    return service.get("/incomeExpenseCategory")
}
