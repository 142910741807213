import service from "@/utils/request";

export const selectAll = () => {
    return service.get("/balance")
}

export const addDebt = (debtForm) => {
    return service.post("/balance",debtForm)
}

export const addAsset = (assetForm) => {
    return service.post("/balance",assetForm)
}
export const showDetail = (balanceId) => {
    return service.get("/balanceOperatedHistory/"+ balanceId)
}

export const onDelete = (balanceId) => {
    return service.delete("/balance/" + balanceId)
}

export const onEdit = (editForm) => {
    return service.put("/balance",editForm)
}


export const transfer = (form) => {
    return service.post("/balance/transfer",form)
}

export const getBalanceDetail = (balanceId) => {
    return service.get("/balance/"+ balanceId)
}

