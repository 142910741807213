import service from "@/utils/request";

export const addOrModifyPlan = (settingForm) => {
    return service.post("/retirementPlan/addOrModifyPlan",settingForm)
}

export const hasOfficialPlan = () => {
    return service.get("/retirementPlan/hasOfficialPlan/")
}

export const getMyLifePlansSetting = () => {
    return service.post("/retirementPlan/getMyLifePlans")
}

export const getMyLifePlan = (planId) => {
    return service.post("/retirementPlan/getMyLifePlan/"+planId)
}

export const addOrModifyGoals = (form) => {
    return service.post("/retirementGoals/addOrModifyGoals",form)
}

export const delGoals = (goalsId) => {
    return service.delete("/retirementGoals/delGoals/" + goalsId)
}




