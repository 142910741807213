<template>
    <div>
        <!--logo与公司名称-->
        <div class="logo" ref="logocollapse">
        <span class="image">
            <img src="../assets/imgs/logo4.png" alt="">
        </span>
            <!--            <h1>FinFree</h1>-->
        </div>

        <nav class="sidebar" ref="collapse">
            <header>
                <div class="balance">
                    <h3 class="title">净资产</h3>
                    <p class="amount">￥{{ Overview.balanceAmount | formatPrice }}</p>

                    <div>
                        <el-progress :text-inside="true" :stroke-width="16"
                                     :percentage="(parseInt(Overview.assetOverview.assetAmount)/(parseInt(Overview.assetOverview.assetAmount)+parseInt(Overview.debtOverview.debtAmount)))*100"
                                     color="var(--blue-2)" define-back-color="var(--bar-color)"
                                     :show-text="false"></el-progress>
                        <div class="overview">
                            <div style="float: left; padding-left: 2px">
                                <h4>资产</h4>
                                <p class="asset">{{ parseInt(Overview.assetOverview.assetAmount) }}</p>
                            </div>
                            <div style="float: right; padding-right: 2px">
                                <h4>负债</h4>
                                <p class="debts">{{ parseInt(Overview.debtOverview.debtAmount) }}</p>
                            </div>

                        </div>
                    </div>

                </div>

                <i class="el-icon-arrow-right toggle" @click="collapse"></i>


            </header>

            <div class="menu-bar">

                <!--                <div class="menu-bar-content">-->
                <div class="detail">
                    <!--资产类别，共6类，现金、储蓄卡、虚拟账号、投资、债权、其他资产-->
                    <!-- 每类资产中，不同种资产-->
                    <ul>
                        <!--虚拟账号-->
                        <!--虚拟账号中支付宝-->
                        <!--虚拟账号中微信-->
                        <!--储蓄卡-->
                        <li clss="sub" v-for="(assetCategory,index) in  Overview.assetOverview.assetCategories"
                            :key="index">
                            <h4>{{ assetCategory.categoryName }}
                                <span class="amount">{{ assetCategory.categoryAmount | formatPrice }}</span>
                            </h4>
                            <ul>
                                <li
                                    v-for="(detail,index) in assetCategory.details" :key="index"
                                    @click="showdetail(detail.balanceId,detail.name,
                                    detail.amount,detail.icon,detail.comment,assetCategory.categoryName,detail.bankcardNumber)">
                                    <a href="#" class="account">
                                        <!--                                            <svg class="icon" aria-hidden="true"-->
                                        <!--                                                 style="font-size: 16px;height: 40px;line-height: 40px;">-->
                                        <!--                                                <use :xlink:href=detail.icon></use>-->
                                        <!--                                            </svg>-->
                                        <div style="flex: 1;">
                                            <p class="name">{{ detail.name }}<span> {{
                                                    detail.bankcardNumber
                                                }}</span></p>
                                            <!--                                                <p class="comment">{{ detail.comment }}</p>-->
                                        </div>
                                        <span class="money">{{ detail.amount | formatPrice }}</span>
                                    </a>
                                    <el-progress :stroke-width="6"
                                                 :percentage="(parseInt(detail.amount)/parseInt(Overview.assetOverview.assetAmount))*100"
                                                 color="var(--blue-2)" define-back-color=""
                                                 :show-text="false"></el-progress>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <div class="detail">
                    <!--房贷-->
                    <!--房贷中长泰豪园-->
                    <!--房贷中山水英伦-->
                    <!--房贷中露谷林语-->
                    <!--车贷-->
                    <ul>
                        <li clss="sub" v-for="(debtsCategory,index) in Overview.debtOverview.debtsCategories"
                            :key="index">
                            <h4>{{ debtsCategory.categoryName }}<span
                                class="amount">{{ debtsCategory.categoryAmount | formatPrice }}</span>
                            </h4>
                            <ul>
                                <li class="sub-detail"
                                    v-for="(detail,index) in debtsCategory.details" :key="index"
                                    @click="showdetail(detail.balanceId,detail.name,detail.amount,
                                    detail.icon,detail.comment,debtsCategory.categoryName,detail.bankcardNumber)">
                                    <a href="#" class="account">
                                        <!--                                            <svg class="icon" aria-hidden="true"-->
                                        <!--                                                 style="font-size: 16px;height: 40px;line-height: 40px;">-->
                                        <!--                                                <use :xlink:href=detail.icon></use>-->
                                        <!--                                            </svg>-->
                                        <div style="flex: 1">
                                            <p class="name">{{ detail.name }}<span> {{
                                                    detail.bankcardNumber
                                                }}</span></p>
                                            <!--                                                <p class="comment">{{ detail.comment }}</p>-->
                                        </div>
                                        <span class="money">{{ detail.amount | formatPrice }}</span>
                                    </a>

                                    <el-progress :stroke-width="6"
                                                 :percentage="(parseInt(detail.amount)/parseInt(Overview.debtOverview.debtAmount))*100"
                                                 color="var(--bar-color)" define-back-color=""
                                                 :show-text="false"></el-progress>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <!--            </div>-->

            </div>

            <div class="bottom-content">
                <a href="#" class="additem" @click="addAssetDebt">
                    <p>+资产</p>
                </a>
            </div>

        </nav>

        <el-dialog title="新增资产" :visible.sync="dialogAddAssetsVisible" width="800px">
            <el-form ref="addAD" :model="assetForm" v-if="dialogAddAssetsVisible">
                <el-form-item prop="name" label="类别" :label-width="formLabelWidth" size="small" :rules="[
                    { required: true, message: '类型不能为空'},]">
                    <el-radio-group v-model="assetForm.name" @input="categorySelectChange">
                        <el-radio-button label="0">现金</el-radio-button>
                        <el-radio-button label="投资账号"></el-radio-button>
                        <el-radio-button label="固定资产"></el-radio-button>
                        <el-radio-button label="储蓄卡"></el-radio-button>
                        <el-radio-button label="虚拟资产"></el-radio-button>
                        <el-radio-button label="22">债权</el-radio-button>
                        <el-radio-button label="卡券"></el-radio-button>
                        <el-radio-button label="23">自定义资产</el-radio-button>
                        <el-radio-button label="负债"></el-radio-button>
                    </el-radio-group>
                </el-form-item>

                <el-form-item prop="categoryId" v-if="assetForm.name === '投资账号'" label="名称"
                              :label-width="formLabelWidth"
                              :rules="[{ required: true, message: '名称不能为空'},]" size="small">
                    <el-radio-group v-model="assetForm.categoryId" @input="categorySelect">
                        <!--                        <el-radio-button label="8"><svg class="icon" aria-hidden="true" style="width: 40px; height: 20px;background: red"><use xlink:href='#icon-yinhangka1'></use></svg><h5 style="background: red">中国</h5></el-radio-button>-->
                        <!--                        STOCK,FUND,BOND,BANK_FINANCING,CRYPTO_CURRENCY,-->
                        <el-radio-button label="18">股票</el-radio-button>
                        <el-radio-button label="19">基金</el-radio-button>
                        <el-radio-button label="20">货币基金</el-radio-button>
<!--                        <el-radio-button label="BOND">债</el-radio-button>-->
<!--                        <el-radio-button label="BANK_FINANCING">银行理财</el-radio-button>-->
                    </el-radio-group>
                </el-form-item>

                <el-form-item v-if="assetForm.name === '固定资产'" prop="categoryId" label="固资类型"
                              :label-width="formLabelWidth"
                              :rules="[{ required: true, message: '名称不能为空'},]" size="small">
                    <el-radio-group v-model="assetForm.categoryId">
                        <el-radio-button label="28">房产</el-radio-button>
                        <el-radio-button label="29">汽车</el-radio-button>
                        <el-radio-button label="30">其他</el-radio-button>
                    </el-radio-group>
                </el-form-item>

                <el-form-item v-if="assetForm.name === '虚拟资产'" prop="categoryId" label="虚资类型"
                              :label-width="formLabelWidth"
                              :rules="[{ required: true, message: '名称不能为空'},]" size="small">
                    <el-radio-group v-model="assetForm.categoryId">
                        <el-radio-button label="3">支付宝</el-radio-button>
                        <el-radio-button label="2">微信</el-radio-button>
                        <el-radio-button label="1">其他虚拟资产</el-radio-button>
                    </el-radio-group>
                </el-form-item>

                <el-form-item v-if="assetForm.name === '储蓄卡'" prop="categoryId" label="名称"
                              :label-width="formLabelWidth"
                              :rules="[{ required: true, message: '名称不能为空'},]" size="small">
                    <el-radio-group v-model="assetForm.categoryId">
                        <el-radio-button label="8">中国银行</el-radio-button>
                        <el-radio-button label="9">农业银行</el-radio-button>
                        <el-radio-button label="10">工商银行</el-radio-button>
                        <el-radio-button label="11">招商银行</el-radio-button>
                        <el-radio-button label="12">建设银行</el-radio-button>
                        <el-radio-button label="13">交通银行</el-radio-button>
                        <el-radio-button label="14">浦发银行</el-radio-button>
                        <el-radio-button label="15">中信银行</el-radio-button>
                        <el-radio-button style="::v-deep .el-radio-button__inner{background:white; border-left: 1px solid #DCDFE6;margin-top: 10px;}" label="16">广发银行</el-radio-button>
                        <el-radio-button style="::v-deep .el-radio-button__inner{background:white; margin-top: 10px;}" label="17">其他银行</el-radio-button>
                    </el-radio-group>
                </el-form-item>

                <el-form-item v-if="assetForm.name === '卡券'" prop="categoryId" label="卡类型"
                              :label-width="formLabelWidth"
                              :rules="[{ required: true, message: '名称不能为空'},]" size="small">
                    <el-radio-group v-model="assetForm.categoryId">
                        <el-radio-button label="24">餐卡</el-radio-button>
                        <el-radio-button label="26">健身卡</el-radio-button>
                        <el-radio-button label="25">美容美发卡</el-radio-button>
                        <el-radio-button label="27">其他卡</el-radio-button>
                    </el-radio-group>
                </el-form-item>

                <el-form-item v-if="assetForm.name === '负债'" prop="categoryId" label="负债类型"
                              :label-width="formLabelWidth"
                              :rules="[{ required: true, message: '名称不能为空'},]" size="small">
                    <el-radio-group v-model="assetForm.categoryId">
                        <el-radio-button label="5">房贷</el-radio-button>
                        <el-radio-button label="4">车贷</el-radio-button>
                        <el-radio-button label="6">信用卡</el-radio-button>
                        <!--                        <el-radio-button label="36">花呗</el-radio-button>-->
                        <el-radio-button label="7">其他贷款</el-radio-button>
                    </el-radio-group>
                </el-form-item>

                <el-form-item v-if="assetForm.name === '储蓄卡' ||assetForm.categoryId === '6'" prop="bankcardNumber"
                              label="卡号后四位"
                              :label-width="formLabelWidth" :rules="[{ required: true, message: '卡号不能为空'},]">
                    <el-input v-model="assetForm.bankcardNumber" autocomplete="off" style="width: 300px;"></el-input>
                </el-form-item>

                <el-form-item
                    v-if="assetForm.name === '23' || assetForm.name === '22' || assetForm.categoryId === '1' ||  assetForm.categoryId === '17' || assetForm.categoryId === '27' || assetForm.categoryId === '30' || assetForm.categoryId === '7'"
                    prop="defineName" label="名称"
                    :label-width="formLabelWidth" :rules="[{ required: true, message: '名称不能为空'},]">
                    <el-input v-model="assetForm.defineName" autocomplete="off" style="width: 300px;"></el-input>
                </el-form-item>

                <!--                <div v-show="inputAmountVisible">-->
                <el-form-item v-if="inputAmountVisible" prop="amount" label="金额" :label-width="formLabelWidth"
                              :rules="[{ required: true, message: '金额不能为空'},]">
                    <el-input type="number" v-model="assetForm.amount" autocomplete="off" style="width: 300px;"></el-input>
                    <!--                        <el-input v-if="form.selectValue === 'B'" v-model="assetForm.amount"></el-input>-->
                </el-form-item>
                <!--                </div>-->

                <!--                股票、基金-->
                <!--                <div class="addInvestmentForm" v-show="inputAmountInvestmentVisible">-->
                <el-form-item
                    v-if="(assetForm.categoryId == '18') || (assetForm.categoryId == '19') || (assetForm.categoryId == '20')"
                    prop="symbolName" label="证券名称"
                    :label-width="formLabelWidth">
                    <el-autocomplete value-key="symbolName" v-model="investmentForm.symbolName"
                                     :fetch-suggestions="fetchResults" @select="selectResult" style="width: 380px;">
                        <template slot-scope="{item}">
                            <span class="addr">{{ item.symbolName + '&nbsp;&nbsp;' + item.symbolCode }}</span>
                        </template>
                    </el-autocomplete>
                </el-form-item>
                <!--                </div>-->

                <!--                <div v-if="assetForm.categoryId != '20'" class="addInvestmentForm"-->
                <!--                     v-show="inputAmountInvestmentVisible">-->
                <el-form-item v-if="(assetForm.categoryId == '18') || (assetForm.categoryId == '19')"
                              prop="holdQuantity"
                              label="持有份额" :label-width="formLabelWidth">
                    <el-input v-model="investmentForm.holdQuantity" autocomplete="off" style="width: 380px;"
                              type="number"></el-input>
                </el-form-item>
                <!--                </div>-->

                <!--                <div v-if="assetForm.categoryId != '20'" class="addInvestmentForm"-->
                <!--                     v-show="inputMoneyInvestmentVisible">-->
                <el-form-item v-if="(assetForm.categoryId == '18') || (assetForm.categoryId == '19')" prop="holdPrice"
                              label="每份价格" :label-width="formLabelWidth">
                    <el-input v-model="investmentForm.holdPrice" autocomplete="off" style="width: 300px;"
                              type="number"></el-input>
                    <el-button style="margin-left: 10px">刷新</el-button>
                </el-form-item>
                <!--                </div>-->

                <!--                货币基金-->
                <!--                <div v-if="assetForm.categoryId == '20'" class="addInvestmentForm"-->
                <!--                     v-show="inputAmountInvestmentVisible">-->
                <el-form-item v-if="(assetForm.categoryId == '20')"
                              prop="holdQuantity" label="持有总金额(元)" :label-width="formLabelWidth">
                    <el-input v-model="investmentForm.holdQuantity" autocomplete="off" style="width: 380px;"
                              type="number"></el-input>
                </el-form-item>
                <!--                </div>-->

                <!--                <div  class="addInvestmentForm" v-show="inputMoneyInvestmentVisible">-->
                <el-form-item v-if="(assetForm.categoryId == '20')"
                              label="每份价格" :label-width="formLabelWidth">
                    <el-input v-model="investmentForm.holdPrice" autocomplete="off" style="width: 300px;"
                              type="number" disabled></el-input>
                    <el-button style="margin-left: 10px">刷新</el-button>
                </el-form-item>
                <!--                </div>-->

                <!--                还款计划-->

                <el-form-item v-if="assetForm.name === '负债'" prop="relatedAccount" label="关联账号"
                              :label-width="formLabelWidth"
                              :rules="[{ required: true, message: '名称不能为空'},]" size="small">
                    <el-radio-group v-model="assetForm.relatedAccount">
                        <el-radio-button label="1">是</el-radio-button>
                        <el-radio-button label="0">否</el-radio-button>
                    </el-radio-group>
                </el-form-item>

                <el-form-item prop="repaymentNum" v-if="assetForm.name === '负债' && assetForm.relatedAccount=='1' "
                              label="还款期数"
                              :label-width="formLabelWidth"
                              :rules="[{ required: true, message: '还款期数不能为空'},]">
                    <el-input v-model="assetForm.repaymentNum" autocomplete="off" style="width: 300px;"></el-input>
                </el-form-item>

                <el-form-item prop="repaymentAmount" v-if="assetForm.name === '负债' && assetForm.relatedAccount=='1' "
                              label="每期还款金额"
                              :label-width="formLabelWidth"
                              :rules="[{ required: true, message: '每期还款金额不能为空'},]">
                    <el-input v-model="assetForm.repaymentAmount" autocomplete="off" style="width: 300px;"></el-input>
                </el-form-item>


                <el-form-item prop="accountId" v-if="assetForm.name === '负债' && assetForm.relatedAccount=='1'"
                              :rules="[{ required: true, message: '每期还款金额不能为空'},]" label="关联账户"
                              :label-width="formLabelWidth">
                    <el-select v-model="assetForm.accountId" placeholder="请选择">
                        <el-option
                            v-for="item in optionsLOAN"
                            :key="item.value"
                            :label="item.text"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>


                <el-form-item prop="repaymentDay" v-if="assetForm.name === '负债' && assetForm.relatedAccount=='1' "
                              label="每月扣款日"
                              :label-width="formLabelWidth"
                              :rules="[{ required: true, message: '每月扣款日不能为空'},]">
                    <el-slider v-model="assetForm.repaymentDay" :min="1" :max="31" style="width:260px;"></el-slider>
                    <!--                    <el-input v-model="assetForm.repaymentDay" autocomplete="off" style="width: 300px;"></el-input>-->
                </el-form-item>


                <el-form-item prop="comment" label="备注" :label-width="formLabelWidth">
                    <el-input v-model="assetForm.comment" autocomplete="off" maxlength="12" type="text" show-word-limit
                              style="width: 380px;" placeholder="选填">
                    </el-input>
                </el-form-item>

            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogAddAssetsVisible = false">取 消</el-button>
                <el-button type="primary" @click="addAsset('addAD')">保 存</el-button>
            </div>

        </el-dialog>

        <el-dialog title="资产明细" :visible.sync="dialogUpdateVisible" width="900px">

            <div class="dialog-detail-main">

                <div class="dialogDetailContent">

                    <div class="dialogDetailTitle">
<!--                        <svg class="icon" aria-hidden="true">
                            <use :xlink:href=editForm.icon></use>
                        </svg>-->
                        <img v-if="editForm.icon != '' " :src="require(`../assets/imgs/${editForm.icon}.png`)" width="50px" height="50px"/>
                    </div>

                    <p class="total">￥{{ editForm.amount }}</p>

                    <h4 class="titleDialogDetailContent">变动记录</h4>
                    <ul class="dialogDetailContent-li">
                        <li v-for="(item,index) in detailTable" :key="index">
                            <span style="float: right">{{ item.createDate }}</span>
                            <h4 v-if="index==0">首次记录</h4>
                            <h4 v-if="index!=0">更新</h4>
                            <p style="padding-top: .2rem">金额： ￥{{ item.amountAfter }}</p>
                        </li>
                    </ul>

                </div>

                <div class="dialogDetail">

                    <div class="subTitle">
                        <h3>编辑</h3>
                    </div>

                    <div class="itemDialoge">
                        <h4>类别</h4>
                        <input v-model="editForm.categoryName" disabled/>
                    </div>

                    <div class="itemDialoge">
                        <h4>名称</h4>
                        <input v-model="editForm.name" placeholder="名称"/>
                    </div>

                    <div class="itemDialoge"
                         v-if="editForm.categoryName === '储蓄卡' || editForm.categoryName === '信用卡'">
                        <h4>银行卡号后四位</h4>
                        <input type="number" v-model="editForm.bankcardNumber" placeholder="银行卡号后四位"/>
                    </div>

                    <div class="itemDialoge">
                        <h4>金额</h4>
                        <input type="number" v-model="editForm.amount" placeholder="金额"/>
                    </div>

                    <div class="itemDialoge">
                        <h4>备注</h4>
                        <input v-model="editForm.comment" placeholder="备注"/>
                    </div>


                    <div class="subTitle"
                         v-if="editForm.categoryName == '现金' || editForm.categoryName == '虚拟资产' || editForm.categoryName == '储蓄卡' || editForm.categoryName == '自定义资产'">
                        <h3 style="padding-top: 3rem">转账</h3>

                        <div class="itemDialoge">
                            <h4>是否转账</h4>
                            <el-radio-group v-model="isTransfer">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="0">否</el-radio>
                            </el-radio-group>
                        </div>
                    </div>

                    <div
                        v-if="(editForm.categoryName == '现金' || editForm.categoryName == '虚拟资产' || editForm.categoryName == '储蓄卡' || editForm.categoryName == '自定义资产') && isTransfer">
                        <div class="itemDialoge">
                            <h4>转出金额</h4>
                            <input type="number" v-model="transferForm.tranAmount" placeholder="转出金额"/>
                        </div>

                        <div class="itemDialoge">
                            <h4>转入账户</h4>
                            <el-select v-model="transferForm.tranIn" placeholder="请选择">
                                <el-option
                                    v-for="item in optionsTransfer"
                                    :key="item.value"
                                    :label="item.text"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>


                    </div>


                    <div class="subTitle"
                         v-if="editForm.categoryName == '车贷' || editForm.categoryName == '房贷' || editForm.categoryName == '信用卡' || editForm.categoryName == '其他贷款'">
                        <h3 style="padding-top: 3rem">还款计划</h3>

                        <div class="itemDialoge">
                            <h4>关联账号</h4>
                            <el-radio-group v-model="editForm.relatedAccount">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="0">否</el-radio>
                            </el-radio-group>
                        </div>
                    </div>

                    <div
                        v-if="(editForm.categoryName == '车贷' ||editForm.categoryName == '房贷' || editForm.categoryName == '信用卡' || editForm.categoryName == '其他贷款') && editForm.relatedAccount=='1'">
                        <div class="itemDialoge">
                            <h4>还款期数</h4>
                            <input type="number" v-model="editForm.repaymentNum" placeholder="还款期数"/>
                        </div>

                        <div class="itemDialoge">
                            <h4>每期金额</h4>
                            <input type="number" v-model="editForm.repaymentAmount" placeholder="每期还款金额"/>
                        </div>

                        <div class="itemDialoge">
                            <h4>关联账户</h4>
                            <input v-model="editForm.accountName" placeholder="关联账户"/>
                        </div>

                        <div class="itemDialoge">
                            <h4>每月扣款日</h4>
                            <el-slider v-model="editForm.repaymentDay" :min="1" :max="31"
                                       style="width:260px;"></el-slider>
                        </div>

                    </div>


                </div>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogUpdateVisible = false">取 消</el-button>
                <el-button type="primary" @click="onDelete">删 除</el-button>
                <el-button type="primary" @click="onEdit">修 改</el-button>
                <el-button v-if="(editForm.categoryName == '现金' || editForm.categoryName == '虚拟资产' || editForm.categoryName == '储蓄卡' || editForm.categoryName == '自定义资产') && isTransfer"
                           type="primary" @click="onTransfer">转 账</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import "../assets/css/base.css"
import "../assets/font/iconfont"
import {addAsset, addDebt, onDelete, onEdit, selectAll, showDetail, getBalanceDetail, transfer} from "@/api/sidebar"
import {chartnetworth} from "@/api/overview";
import {addInvest, getSymbol, getSymbolPrice} from "@/api/investment";
import "./SideAside.css"
import {addOrModifyGoals} from "@/api/retireplan";


export default {
    // el: "#app",
    mounted() {
        this.selectRelatedAcount();
        this.select();
        window.addEventListener('resize', this.handleResize);
        this.handleResize();

    },
    computed: {
        dataRefresh() {
            return this.$store.state.dataRefresh;
        }
    },
    watch: {
        dataRefresh() {
            console.log("isRefreshData", this.$store.state.dataRefresh)

            selectAll().then((resp) => {
                this.Overview = resp.data;
            });
        },
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },

    data() {
        return {
            isTransfer: 0,

            transferForm: {
                relatedName: '',

                tranAmount: '',
                tranIn: '',
                tranOut: '',
                comment: '',

            },

            optionsLOAN: [{text: '中国银行', value: 0}],
            optionsTransfer: [{text: '中国银行', value: 0}],


            categoryID: '',
            categorySubID: '',

            investmentForm: {
                categoryId: "",
                currentPrice: '',
                holdPrice: '',
                holdQuantity: '',
                id: '',
                settlePrice: '',
                symbol: "",
                symbolCode: "",
                symbolName: "",
                userId: '',
            },

            timeout: null,
            results: [],

            inputAmountInvestmentVisible: false,

            inputMoneyInvestmentVisible: false,

            inputAmountVisible: true,

            // editForm: {
            //     balanceId: '',
            //     name: '',
            //     amount: '',
            //     icon: '',
            //     comment: '',
            // },


            editForm: {
                accountId: 0,

                amount: 0,
                balanceId: 0,
                bankcardNumber: "",
                categoryId: 0,
                comment: "",
                name: "",

                categoryName: '',
                accountName: '',

                createDate: "",
                relatedAccount: 1,
                repaymentAmount: 0,
                repaymentDay: 0,
                repaymentNum: 0,
                userId: '',
                icon: '',
            },

            detailTable: [{
                createDate: '2016-05-04',
                amountBefore: '10000',
                amountAfter: '200'
            }, {
                createDate: '2016-05-02',
                amountBefore: '1518',
                amountAfter: '10000'
            }, {
                createDate: '2016-05-01',
                amountBefore: '0',
                amountAfter: '1518'
            }],

            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        picker.$emit('pick', new Date());
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24);
                        picker.$emit('pick', date);
                    }
                }, {
                    text: '一周前',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', date);
                    }
                }]
            },

            dialogAddAssetsVisible: false,
            dialogAddDebtsVisible: false,

            dialogUpdateVisible: false,
            activeIndex: '1',
            activeIndex2: '1',
            percentage: 70,
            form: {
                name: '',
                money: '',
                date: '',
            },
            debtForm: {
                userId: '2',
                categoryId: '6',
                // name: '',
                amount: '',
                comment: '',
                // createDate: new Date(),
            },
            assetForm: {
                userId: '2',
                categoryId: '',
                name: '',
                amount: '',
                comment: '',
                bankcardNumber: '',
                defineName: '',
                // createDate: new Date(),

                accountId: '',
                accountName: '',
                relatedAccount: '1',
                repaymentAmount: '',
                repaymentDay: 5,
                repaymentNum: '',
            },
            formLabelWidth: '120px',
            options: [{
                label: '现金',
                options: [{
                    value: '0',
                    label: '现金'
                }]
            }, {
                label: '虚拟资产',
                options: [{
                    value: '3',
                    label: '支付宝'
                }, {
                    value: '2',
                    label: '微信'
                }, {
                    value: '1',
                    label: '其他虚拟资产'
                }]
            }, {
                label: '固定资产',
                options: [{
                    value: '28',
                    label: '房产'
                }, {
                    value: '29',
                    label: '汽车'
                }, {
                    value: '30',
                    label: '其他'
                }]
            }, {
                label: '储蓄卡',
                options: [{
                    value: '8',
                    label: '中国银行'
                }, {
                    value: '9',
                    label: '农业银行'
                }, {
                    value: '10',
                    label: '工商银行'
                }, {
                    value: '11',
                    label: '招商银行'
                }, {
                    value: '12',
                    label: '建设银行'
                }, {
                    value: '13',
                    label: '交通银行'
                }, {
                    value: '14',
                    label: '浦发银行'
                }, {
                    value: '15',
                    label: '中信银行'
                }, {
                    value: '16',
                    label: '广发银行'
                }, {
                    value: '17',
                    label: '其他银行'
                }]
            }, {
                label: '投资账号',
                options: [{
                    value: '18',
                    label: '证券投资'   //'股票'
                    // }, {
                    //     value: '19',
                    //     label: '基金'
                    // }, {
                    //     value: '20',
                    //     label: '黄金'
                    // }, {
                    //     value: '21',
                    //     label: '其他投资'
                }]
            }, {
                label: '债权',
                options: [{
                    value: '22',
                    label: '债权'
                }]
            }, {
                label: '卡券',
                options: [{
                    value: '24',
                    label: '餐卡'
                }, {
                    value: '25',
                    label: '美容美发卡'
                }, {
                    value: '26',
                    label: '健身卡'
                }, {
                    value: '27',
                    label: '其他卡'
                },]

                //                 < el - radio - group
                //             v-model = "debtForm.categoryId"
                //             size = "medium" >
                //             < el - radio - button
                //             label = "6" > < span
                //             className = "iconfont icon-fangdai" > < /span><h5>信用卡</
                //             h5 >
                //         < /el-radio-button>
                //             <el-radio-button label="4"><span className="iconfont icon-chuzuchetianchong"></span><h5>车贷</h5>
                //             </el-radio-button>
                //             <el-radio-button label="5"><span className="iconfont icon-fangdai"></span><h5>房贷</h5>
                //             </el-radio-button>
                //             <el-radio-button label="7"><span className="iconfont icon-qita"></span><h5>其他</h5>
                //             </el-radio-button>
                // </el-radio-group>

            }, {
                label: '自定义资产',
                options: [{
                    value: '23',
                    label: '自定义资产'
                }]
            }, {
                label: '信用卡',
                options: [{
                    value: '6',
                    label: '信用卡'
                }]
            }, {
                label: '车贷',
                options: [{
                    value: '4',
                    label: '车贷'
                }]
            }, {
                label: '房贷',
                options: [{
                    value: '5',
                    label: '房贷'
                }]
            }, {
                label: '其他负债',
                options: [{
                    value: '7',
                    label: '其他负债'
                }]
            },

            ],
            value: '',

            Overview: {
                balanceAmount: "",
                assetOverview: {
                    assetAmount: "", assetCategories: [{
                        categoryName: '',
                        categoryAmount: '',
                        details: [{
                            balanceId: '',
                            amount: '',
                            name: '',
                            icon: '',
                            comment: '',
                            bankcardNumber: '',
                        },]
                    }]
                },
                debtOverview: {
                    debtAmount: "", debtsCategories: [{
                        categoryName: '',
                        categoryAmount: '',
                        details: [{
                            balanceId: '',
                            amount: '',
                            name: '',
                            icon: '',
                            comment: '',
                        },]
                    }]
                }
            },

            /*Overview: {
                balanceAmount: "3,000,000,00",
                assetOverview: {
                    assetAmount: "90,000.00", assetCategories: [{
                        categoryName: '虚拟账号',
                        categoryAmount: '118,234.00',
                        details: [{
                            balanceId: '0',
                            amount: '12,345.00',
                            name: '支付宝',
                            icon: '#icon-zhifubao',
                            comment: '我是备注我是备注',
                            bankcardNumber: '',
                            // createDate: ''
                        },]
                    }]
                },
                debtOverview: {
                    debtAmount: "50,000.00", debtsCategories: [{
                        categoryName: '房贷',
                        categoryAmount: '118,234.00',
                        details: [{
                            balanceId: '0',
                            amount: '12,345.00',
                            name: '长泰豪园',
                            icon: '#icon-fangdai',
                            comment: '我是备注我是备注',
                            // createDate: ''
                        },]
                    }]
                }
            }*/
        }
    },

    methods: {

        onTransfer() {
            this.transferForm.tranOut = this.editForm.balanceId

            if (this.transferForm.tranAmount == '') {
                this.$message({
                    message: '转账金额不能为空',
                    duration: '500',
                });
                return;
            }

            if (this.transferForm.tranIn == '') {
                this.$message({
                    message: '转入账号不能为空',
                    duration: '500',
                });
                return;
            }


            transfer(this.transferForm).then(resp => {
                if (resp.code === 1) {

                    this.isTransfer = 0;
                    this.transferForm.tranOut = '';
                    this.transferForm.tranIn = '';
                    this.transferForm.tranAmount = '';


                    this.dialogUpdateVisible = false;
                    this.select();
                    this.$message({
                        message: '转账成功',
                        type: 'success',
                        duration: '500',
                    });
                }
            })
        },

        selectRelatedAcount() {
            selectAll().then((resp) => {
                let options = [];
                let optionsTransfer = [];
                let assets = resp.data.assetOverview.assetCategories;
                assets.forEach(asset => {
                    if (asset.categoryName == '虚拟资产' || asset.categoryName == '现金' ||
                        asset.categoryName == '储蓄卡') {
                        asset.details.forEach(detail => {
                                options.push({text: detail.name + ' 余额:' + detail.amount, value: detail.balanceId});
                                if (detail.balanceId != this.editForm.balanceId) {
                                    optionsTransfer.push({
                                        text: detail.name + ' 余额:' + detail.amount,
                                        value: detail.balanceId
                                    });
                                }
                            }
                        )
                    }
                })
                this.optionsLOAN = options;
                this.optionsTransfer = optionsTransfer
            });
        },

        fetchResults(queryString, cb) {
            if (this.investmentForm.symbolName.trim() !== '') {
                // STOCK,FUND,BOND,BANK_FINANCING,CRYPTO_CURRENCY
                var invetmentType = '';
                if (this.assetForm.categoryId === '18') {
                    invetmentType = 'STOCK';
                } else if (this.assetForm.categoryId === '19') {
                    invetmentType = 'FUND';
                } else if (this.assetForm.categoryId === '20') {
                    invetmentType = 'MONEY_FUND';
                }


                getSymbol(this.investmentForm.symbolName, invetmentType).then(resp => {
                    var selectName = resp.data;

                    // var results = queryString ? selectName.filter(this.createSearchFilter(queryString)) : selectName;
                    // // 调用 callback 返回建议列表的数据
                    clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                        cb(selectName);
                    }, 100 * Math.random());

                    // console.log("selectName = resp.data;",selectName)

                })
            } else {
                this.results = [];
            }
        },

        selectResult(item) {
            // 处理用户选择的结果
            // console.log("selectResult", item);
            getSymbolPrice(item.symbol).then(resp => {
                // console.log("getSymbolPrice", resp.data);
                this.investmentForm.holdPrice = resp.data.price;
                this.investmentForm.symbolCode = resp.data.symbolCode;
                this.investmentForm.symbolName = resp.data.symbolName;
                this.investmentForm.symbol = resp.data.symbol;

            })

        },

        addAssetDebt() {
            this.dialogAddAssetsVisible = true;
            this.debtForm.amount = '';
            this.debtForm.categoryId = '';
            this.debtForm.comment = '';
            this.assetForm.amount = '';
            this.assetForm.categoryId = '';
            this.assetForm.comment = '';
            this.assetForm.name = '';
            this.assetForm.bankcardNumber = '';
            this.assetForm.defineName = '';
            this.inputAmountInvestmentVisible = false;
            this.inputMoneyInvestmentVisible = false;
            this.inputAmountVisible = true;
            // this.$refs.amount.style.setProperty('display', 'block');
            // this.$refs.moneyInvestment.style.setProperty('display', 'none');
            // this.$refs.amountInvestment.style.setProperty('display', 'none');
        },

        categorySelectChange() {
            this.assetForm.categoryId = '';
            this.inputAmountInvestmentVisible = false;
            this.inputMoneyInvestmentVisible = false;
            this.inputAmountVisible = true;
        },

        categorySelect(value) {

            console.log("!!!!!!!!!!!!categorySelect", value)
            if (value === '18' || value === '19' || value === '20') {
                // console.log("value", value);
                // this.$refs.amount.style.setProperty('display', 'none');
                // this.$refs.moneyInvestment.style.setProperty('display', 'block');
                // this.$refs.amountInvestment.style.setProperty('display', 'block');
                // $('#duration').css('display','none');  //组件完全隐藏，不占用显示时占用的位置
                this.inputAmountInvestmentVisible = true;
                this.inputMoneyInvestmentVisible = true;
                this.inputAmountVisible = false;
            } else {
                this.inputAmountInvestmentVisible = false;
                this.inputMoneyInvestmentVisible = false;
                this.inputAmountVisible = true;
            }
        },

        collapse() {
            this.$refs.collapse.classList.toggle('close');
            this.$refs.logocollapse.classList.toggle('close');
        },

        handleResize() {
            if ((window.innerWidth < 1400) && !this.$refs.collapse.classList.contains('close')) {
                this.collapse();
            }
        },

        //查询数据
        select() {
            selectAll().then((resp) => {
                // console.log("selectAll",resp.data);
                this.Overview = resp.data;
                this.$store.commit('SET_TABLE_DATA', resp.data.assetOverview.assetCategories);
            });


            chartnetworth().then((resp) => {
                this.$store.commit('SET_CHART_DATA', resp.data);
            });
        },

        //新增负债
        addDebt() {

            if (this.debtForm.amount == '') {
                this.$message('金额不能为空');
                return;
            }
            if (this.debtForm.categoryId == '') {
                this.$message('类型不能为空');
                return;
            }

            addDebt(this.debtForm).then(resp => {
                if (resp.data == true) {
                    this.dialogAddDebtsVisible = false;
                    this.select();
                    this.$message({
                        message: '恭喜你，添加成功',
                        type: 'success',
                        duration: '500',
                    });

                }
            })
        },

        //新增资产
        addAsset(formName) {

            if (this.assetForm.name === '0' || this.assetForm.name === '23' || this.assetForm.name === '22') {
                this.assetForm.categoryId = this.assetForm.name;
                if (this.assetForm.name === '0') {
                    this.assetForm.name = '现金'
                }
            }

            // if (this.assetForm.categoryId == '') {
            //     this.$message('类型不能为空');
            //     return;
            // }

            if (this.assetForm.categoryId === '18' || this.assetForm.categoryId === '19' || this.assetForm.categoryId === '20') {

                if (this.assetForm.categoryId === '20') {
                    if (this.investmentForm.holdQuantity == '' || this.investmentForm.symbolName == '') {
                        this.$message('数据不能为空');
                        return;
                    }
                } else {
                    if (this.investmentForm.holdPrice == '' || this.investmentForm.holdQuantity == '' || this.investmentForm.symbolName == '') {
                        this.$message('数据不能为空');
                        return;
                    }
                }

                this.investmentForm.categoryId = this.assetForm.categoryId;

                addInvest(this.investmentForm).then(resp => {
                    if (resp.code == 1) {
                        this.investmentForm.holdPrice = '';
                        this.investmentForm.holdQuantity = '';
                        this.investmentForm.symbolCode = '';
                        this.investmentForm.symbolName = '';
                        this.investmentForm.symbol = '';

                        this.dialogAddAssetsVisible = false;
                        this.select();
                        this.$message({
                            message: '恭喜你，添加成功',
                            type: 'success',
                            duration: '500',
                        });

                    }
                });

                // this.$refs[formName].validate((valid) => {
                //     if (valid) {
                //
                //     } else {
                //         console.log('error!', this.investmentForm)
                //         return false;
                //     }
                // })


            } else {

                // if (this.assetForm.categoryId === '8' || this.assetForm.categoryId === '9'
                //     || this.assetForm.categoryId === '11' || this.assetForm.categoryId === '12'
                //     || this.assetForm.categoryId === '13' || this.assetForm.categoryId === '14'
                //     || this.assetForm.categoryId === '15' || this.assetForm.categoryId === '16'
                //     || this.assetForm.categoryId === '17' || this.assetForm.categoryId === '10') {
                //     if (this.assetForm.bankcardNumber == '') {
                //         this.$message('卡号不能为空');
                //         return;
                //     }
                // }

                // if (this.assetForm.categoryId === '23') {
                //     if (this.assetForm.defineName == '') {
                //         this.$message('自定义资产名称不能为空');
                //         return;
                //     }
                // }
                //
                // if (this.assetForm.amount == '') {
                //     this.$message('金额不能为空');
                //     return;
                // }


                // options: [{
                //     label: '现金',
                //     options: [{
                //         value: '0',
                //         label: '现金'
                //     }]
                // }, {


                this.options.forEach(item => {
                    // console.log('item',item.label,this.assetForm.categoryId,item);

                    if (item.label == this.assetForm.name) {
                        item.options.forEach(x => {
                            // console.log('label',x.label,this.assetForm.categoryId,x);
                            if (x.value == this.assetForm.categoryId) {
                                this.assetForm.name = x.label;
                            }
                        })
                    }
                })

                if (this.assetForm.categoryId === '23' ||
                    this.assetForm.categoryId === '1' ||
                    this.assetForm.categoryId === '17' ||
                    this.assetForm.categoryId === '27' ||
                    this.assetForm.categoryId === '7' ||
                    this.assetForm.categoryId === '22' ||
                    this.assetForm.categoryId === '30') {
                    this.assetForm.name = this.assetForm.defineName
                    console.log(this.assetForm.defineName)
                }

                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        addAsset(this.assetForm).then(resp => {
                            if (resp.data == true) {
                                this.dialogAddAssetsVisible = false;
                                this.select();
                                this.$message({
                                    message: '恭喜你，添加成功',
                                    type: 'success',
                                    duration: '500',
                                });
                            }
                        });
                    } else {
                        console.log('error!', this.assetForm)
                        return false;
                    }
                })


            }


        },

        showdetail(balanceId, name, amount, icon, comment, categoryName, bankcardNumber) {

            if (categoryName === '投资账号') {
                this.$store.commit("SET_isActiveIndex","/product/investment")
                this.$router.push("/product/investment")

            } else {
                this.editForm.balanceId = balanceId;
                this.editForm.name = name;
                this.editForm.amount = amount;
                this.editForm.icon = icon;
                this.editForm.comment = comment;
                this.editForm.categoryName = categoryName
                this.editForm.bankcardNumber = bankcardNumber;

                this.dialogUpdateVisible = true;
                showDetail(this.editForm.balanceId).then(resp => {
                    this.detailTable = resp.data;
                    this.editForm.userId = resp.data.userId;
                })

                // if (categoryName == '车贷' || categoryName == '房贷' || categoryName == '信用卡' || categoryName == '其他贷款') {
                getBalanceDetail(balanceId).then(resp => {
                    this.editForm.repaymentAmount = resp.data.repaymentAmount;
                    this.editForm.relatedAccount = resp.data.relatedAccount;
                    this.editForm.repaymentNum = resp.data.repaymentNum;
                    this.editForm.repaymentDay = resp.data.repaymentDay;
                    this.editForm.accountId = resp.data.accountId;
                    this.editForm.categoryId = resp.data.categoryId;

                    if (categoryName == '车贷' || categoryName == '房贷' || categoryName == '信用卡' || categoryName == '其他贷款') {
                        if (this.editForm.relatedAccount && this.editForm.relatedAccount != 0) {
                            getBalanceDetail(this.editForm.accountId).then(resp => {
                                this.editForm.accountName = resp.data.name;
                            })
                        }
                    }

                })
                // }
            }
        },

// 删除
        onDelete() {

            onDelete(this.editForm.balanceId).then(resp => {
                if (resp.data == true) {
                    this.dialogUpdateVisible = false;
                    this.select();
                    this.$message({
                        message: '删除成功',
                        type: 'success',
                        duration: '500',
                    });

                }
            })
        },

// 修改
        onEdit() {

            if (this.editForm.amount == '') {
                this.$message({
                    message: '金额不能为空',
                    duration: '500',
                });
                return;
            }

            // console.log('editForm', this.editForm)

            onEdit(this.editForm).then(resp => {
                if (resp.data == true) {
                    this.dialogUpdateVisible = false;
                    this.select();
                    this.$message({
                        message: '修改成功',
                        type: 'success',
                        duration: '500',
                    });

                }
            })
        },
    },
}
</script>
