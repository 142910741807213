<template>
    <div class="header ">
        <div class="navWrapper">
            <!--导航栏-->
            <div class="nav">
                    <li @click="clickNav(title,index)" v-for="(title,index) in navtitle" :key="index"
                        :class="{ active: title.path === isActive  }">{{ title.label }}
                    </li>
            </div>

            <!--用户栏-->
            <div class="user">

<!--                导入账单UI没有更新，暂时屏蔽！！！！！！！-->
<!--                <button class="uploadBtn" @click="dialogUploadVisible = true">导入账单</button>-->

                <el-dropdown>
                    <div
                        style="width: 36px; height: 36px; border-radius: 100px; border:2px solid var(--blue-1); text-align: center">
                        <i class="el-icon-user" style="font-size: 24px; line-height: 36px; color: var(--blue-1)"></i>
                    </div>

                    <el-dropdown-menu slot="dropdown">
<!--                        <el-dropdown-item divided>个人设置</el-dropdown-item>-->
                        <el-dropdown-item @click.native="logout">退出系统</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>


        <el-dialog title="" :visible.sync="dialogUploadVisible" width="1200px">

            <div class="dialogUploadDetail" v-if="dialogUploadVisible">
                <div class="dialogUploadDetailTitle">
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href='#icon-qita'></use>
                    </svg>
                    <b>导入账单</b>
                </div>

                <input type="file" id="wechatFile" name="file" style="display:none" @change="handleFileUpload"/>
                <input type="file" id="zfbFile" name="file" style="display:none" @change="handleZFBFileUpload"/>

                <el-button class="fileUploadBtn" @click="wechatBtn">导入微信账单</el-button>
                <el-button class="fileUploadBtn" @click="zfbBtn">导入支付宝账单</el-button>

                <el-input type="text" value="" disabled id="inputFileAgent" style="width: 400px; padding-left: 10px"/>
                <!--                <el-button  @click="zfbBtn">校验</el-button>-->
                <el-button @click="onFirstImported" type="primary" v-show="firstImportedBtn" style="float: right">校验
                </el-button>

            </div>

            <div v-show="dialogTable" style="float: right; padding-right: 4rem; padding-bottom: 2rem">
                <span>是否关联账号 </span>
                <el-switch
                    v-model="uploadForm.relatedAccount"
                    active-color="#4771d6"
                    inactive-color="#ddd">
                </el-switch>
            </div>

            <el-table
                :row-class-name="tableRow"
                v-show="dialogTable"
                ref="multipleTable"
                :data="resultForm.preResult"
                tooltip-effect="dark"
                style="width: 100%"
                @select="handleSelectItem"
                @selection-change="handleSelectionChange"
                @cell-click="cellclick">
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column
                    type="index"
                    width="50">
                </el-table-column>
                <!--                <el-table-column-->
                <!--                    prop="id"-->
                <!--                    label="id"-->
                <!--                    align="center">-->
                <!--                </el-table-column>-->
                <el-table-column
                    prop="createDate"
                    label="交易时间"
                    width="180"
                    align="center">
                </el-table-column>
                <!--      prop="categorySubName"          -->
                <el-table-column
                    prop="categoryId"
                    label="类型"
                    width="160"
                    align="center">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.categoryId" placeholder="请选择">
                            <el-option
                                v-for="item in categoryOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="comment"
                    label="商品名称"
                    width="240"
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="amount"
                    label="金额"
                    width="80"
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="type"
                    label="收/支"
                    width="100"
                    align="center"
                    :formatter="IEFomat">

                </el-table-column>
                <el-table-column
                    prop="payWay"
                    label="付款方式"
                    width="80"
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="msg"
                    label="状态"
                    width="200"
                    align="center">
                </el-table-column>
            </el-table>

            <div slot="footer" class="dialog-footer">
                <el-button v-if="!firstImportedBtn" @click="dialogUploadVisible = false">取 消</el-button>
                <el-button v-if="resultForm.canImport && dialogTable && uploadForm.relatedAccount" type="primary" @click="onRelated">关联资金账号
                </el-button>

                <el-button v-if="resultForm.canImport === false" type="primary" v-show="!firstImportedBtn"
                           @click="onImported">导入
                </el-button>

                <el-button v-if="resultForm.canImport && dialogTable && !uploadForm.relatedAccount" type="primary" @click="onRelated">导入账单
                </el-button>



            </div>
        </el-dialog>

        <el-dialog title="新增资产" :visible.sync="dialogAddAssetsVisible" width="800px">
            <el-form>

                <el-form-item label="类别" :label-width="formLabelWidth" size="small">
                    <el-radio-group v-model="assetForm.name" @input="categorySelectChange">

                        <el-radio-button label="储蓄卡"></el-radio-button>
                        <el-radio-button label="虚拟资产"></el-radio-button>
                        <el-radio-button label="负债"></el-radio-button>
                    </el-radio-group>
                </el-form-item>

                <el-form-item v-if="assetForm.name === '虚拟资产'" prop="" label="名称" :label-width="formLabelWidth"
                              :rules="[{ required: true, message: '名称不能为空'},]" size="small">
                    <el-radio-group v-model="assetForm.categoryId">
                        <el-radio-button label="3">支付宝</el-radio-button>
                        <el-radio-button label="2">微信</el-radio-button>
                        <el-radio-button label="1">其他虚拟资产</el-radio-button>
                    </el-radio-group>
                </el-form-item>

                <el-form-item v-if="assetForm.name === '储蓄卡'" prop="" label="名称" :label-width="formLabelWidth"
                              :rules="[{ required: true, message: '名称不能为空'},]" size="small">
                    <el-radio-group v-model="assetForm.categoryId">
                        <el-radio-button label="8">中国银行</el-radio-button>
                        <el-radio-button label="9">农业银行</el-radio-button>
                        <el-radio-button label="10">工商银行</el-radio-button>
                        <el-radio-button label="11">招商银行</el-radio-button>
                        <el-radio-button label="12">建设银行</el-radio-button>
                        <el-radio-button label="13">交通银行</el-radio-button>
                        <el-radio-button label="14">浦发银行</el-radio-button>
                        <el-radio-button label="15">中信银行</el-radio-button>
                        <el-radio-button label="16">广发银行</el-radio-button>
                        <el-radio-button label="17">其他银行</el-radio-button>
                    </el-radio-group>
                </el-form-item>

                <el-form-item v-if="assetForm.name === '卡券'" prop="" label="名称" :label-width="formLabelWidth"
                              :rules="[{ required: true, message: '名称不能为空'},]" size="small">
                    <el-radio-group v-model="assetForm.categoryId">
                        <el-radio-button label="24">餐卡</el-radio-button>
                        <el-radio-button label="26">健身卡</el-radio-button>
                        <el-radio-button label="25">美容美发卡</el-radio-button>
                        <el-radio-button label="27">其他卡</el-radio-button>
                    </el-radio-group>
                </el-form-item>

                <el-form-item v-if="assetForm.name === '负债'" prop="" label="名称" :label-width="formLabelWidth"
                              :rules="[{ required: true, message: '名称不能为空'},]" size="small">
                    <el-radio-group v-model="assetForm.categoryId">
                        <el-radio-button label="5">房贷</el-radio-button>
                        <el-radio-button label="4">车贷</el-radio-button>
                        <el-radio-button label="6">信用卡</el-radio-button>
                        <el-radio-button label="36">花呗</el-radio-button>
                        <el-radio-button label="7">其他贷款</el-radio-button>
                    </el-radio-group>
                </el-form-item>

                <el-form-item v-if="assetForm.name === '储蓄卡' ||assetForm.categoryId === '6'" prop=""
                              label="卡号后四位"
                              :label-width="formLabelWidth" :rules="[{ required: true, message: '卡号不能为空'},]">
                    <el-input v-model="assetForm.bankcardNumber" autocomplete="off" style="width: 300px;"></el-input>
                </el-form-item>

                <div ref="amount">
                    <el-form-item label="金额" :label-width="formLabelWidth">
                        <el-input v-model="assetForm.amount" autocomplete="off" style="width: 300px;"></el-input>
                        <!--                        <el-input v-if="form.selectValue === 'B'" v-model="assetForm.amount"></el-input>-->
                    </el-form-item>
                </div>

                <el-form-item label="备注" :label-width="formLabelWidth">
                    <el-input v-model="assetForm.comment" autocomplete="off" maxlength="12" type="text" show-word-limit
                              style="width: 380px;" placeholder="选填">
                    </el-input>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogAddAssetsVisible = false">取 消</el-button>
                <el-button type="primary" @click="addAsset">保 存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Cookies from "js-cookie";
import {logout} from "@/api/login";
import {hasOfficialPlan} from "@/api/retireplan";
import isUtf8 from "@/js/isUtf8";
import XLSX from 'xlsx';
import {importIE, relatedIE, selectCategories} from "@/api/ie";
import {Enum} from "@/utils/enums";
import {addAsset} from "@/api/sidebar";


// import message from '/element-ui/lib/message'


const cptable = require("xlsx/dist/cpexcel.js");

export default {
    data() {
        return {
            dialogAddAssetsVisible: false,
            formLabelWidth: '120px',
            assetForm: {
                userId: '2',
                categoryId: '',
                name: '',
                amount: '',
                comment: '',
                bankcardNumber: '',
                defineName: '',
                // createDate: new Date(),
            },

            categoryOptions: [{
                value: '0',
                label: '固定收入'
            }, {
                value: '1',
                label: '被动收入'
            }, {
                value: '2',
                label: '其他收入'
            }, {
                value: '3',
                label: '吃'
            }, {
                value: '4',
                label: '穿'
            }, {
                value: '5',
                label: '住'
            }, {
                value: '6',
                label: '行'
            }, {
                value: '7',
                label: '医疗'
            }, {
                value: '8',
                label: '文化'
            }],

            dialogTable: false,

            uploadForm: {
                orderType: '', //微信。支付宝
                relatedAccount: 'false',
                impData: [{
                    createDate: '',
                    orderNo: '', //交易单号
                    name: '',  //交易对方 商品
                    amount: '',
                    type: '',   //0为支出，1为收入
                    comment: '',
                    payWay: '',
                    orderStatus: '',
                    msg: '',
                },],
            },

            resultForm: {
                canImport: false,
                balanceSheetChanges: [{
                    amount: '',
                    balanceId: '',
                    bankcardNumber: "",
                    categoryId: '',
                    changeAmount: '',
                    comment: "",
                    lastAmount: '',
                    name: "",
                    userId: ''
                }],
                preResult: [{
                    createDate: '',
                    orderNo: '', //交易单号
                    amount: '',
                    type: '',   //0为支出，1为收入
                    comment: '',
                    payWay: '',
                    orderStatus: '',
                    msg: '',
                    // categorySubName: '',
                    categoryId: '',
                },],
            },

            firstImportedBtn: true,

            dialogUploadVisible: false,


            multipleSelection: [],

            navtitle: [
                {
                    path: '/product/overview',
                    name: 'overview',
                    label: '总览',
                },
                {
                    path: '/product/networth',
                    name: 'networth',
                    label: '净值',
                },
                {
                    path: '/product/incomeexpense',
                    name: 'IncomeExpense',
                    label: '收支',
                },
                {
                    path: '/product/retireplan',
                    name: 'retireplan',
                    label: '退休计划',
                },
                {
                    path: '/product/investment',
                    name: 'investment',
                    label: '投资',
                },
            ],
            type: new Enum()
                .add('INCOME', '收入', 1)
                .add('EXPENSE', "支出", 2)
        }
    },

    mounted() {

        selectCategories().then(resp => {
            if (resp.code==1){
                // console.log(resp.data)
                let categories=[];
                resp.data.forEach(item => {
                    categories.push({value:item.id,label:item.categorySubName})
                })

                this.categoryOptions = categories;
            }
        });


        this.$store.commit("SET_isActiveIndex",this.$route.path)

    },

    computed: {
        isActive(){
            return this.$store.state.isActive;
        }
    },

    methods: {
        IEFomat(row, column) {
            if (row.type == 'INCOME') {
                return "收入";
            } else if (row.type == 'EXPENSE') {
                return "支出";
            } else {
                return "-";
            }
        },

        //标红table指定行
        tableRow({row, rowIndex}) {
            // tableRow(row) {

            //判断条件（quantityStock ，warningLimit ，quantityStock 列表字段信息）
            if (row.msg !== '成功') {
                // 后面的css样式
                return 'warning-row';
            }

            // 默认标红第一行
            // if (rowIndex === 0) {
            //   return 'warning-row';
            // }

            return '';
        },

        cellclick(row, column, cell, event) {
            console.log(row, column, cell, event);
            this.assetForm.amount = '';
            this.assetForm.categoryId = '';
            this.assetForm.comment = '';
            this.assetForm.name = '';
            this.assetForm.bankcardNumber = '';
            this.assetForm.defineName = '';
            this.dialogAddAssetsVisible = true;
        },

        categorySelectChange() {
            this.assetForm.categoryId = '';
        },

        addAsset() {

            if (this.assetForm.name === '0' || this.assetForm.name === '23' || this.assetForm.name === '22') {
                this.assetForm.categoryId = this.assetForm.name;
            }

            if (this.assetForm.categoryId == '') {
                this.$message('类型不能为空');
                return;
            }

            if (this.assetForm.categoryId === '8' || this.assetForm.categoryId === '9'
                || this.assetForm.categoryId === '11' || this.assetForm.categoryId === '12'
                || this.assetForm.categoryId === '13' || this.assetForm.categoryId === '14'
                || this.assetForm.categoryId === '15' || this.assetForm.categoryId === '16'
                || this.assetForm.categoryId === '17' || this.assetForm.categoryId === '10') {
                if (this.assetForm.bankcardNumber == '') {
                    this.$message('卡号不能为空');
                    return;
                }
            }

            if (this.assetForm.amount == '') {
                this.$message('金额不能为空');
                return;
            }
            addAsset(this.assetForm).then(resp => {
                if (resp.data == true) {
                    this.dialogAddAssetsVisible = false;

                    this.onImported();

                    this.$message({
                        message: '恭喜你，添加成功',
                        type: 'success',
                        duration: '500',
                    });
                }
            });
        },

        handleSelectItem(selection, row) {

            let selected = selection.length && selection.indexOf(row) !== -1
            console.log("handleSelectItem----row", selected)  // true就是选中，0或者false是取消选中
            // if(selected){
            //     console.log("handleSelectItem----row",row)
            //     if (row.msg !== '成功') {
            //         // 后面的css样式
            //         return 'warning-row';
            //     }
            // }else {
            //     return 'success-row';
            // }
        },

        handleSelectionChange(val) {
            this.multipleSelection = val;
            // console.log('this.multipleSelection-------',this.multipleSelection);
        },

        wechatBtn() {
            this.dialogTable = false
            document.getElementById('wechatFile').click();
            this.uploadForm.orderType = 'WECHAT_PAY';
            this.firstImportedBtn = true;
        },

        zfbBtn() {
            this.dialogTable = false
            document.getElementById('zfbFile').click();
            this.uploadForm.orderType = 'ALI_PAY';
            this.firstImportedBtn = true;
        },

        onRelated() {
            const impData = this.resultForm.preResult
            const data = {
                impData: impData,
                orderType: this.uploadForm.orderType,
                relatedAccount: this.uploadForm.relatedAccount,
            };

            // relatedIE({impData: impData}).then(resp => {
            relatedIE(data).then(resp => {
                if (resp.code === 1) {
                    this.dialogUploadVisible = false;
                    this.
                    this.$message({
                        message: '恭喜你，导入成功!',
                        type: 'success',
                        duration: '500',
                    });
                } else {
                    this.$message({
                        message: '导入失败!',
                        duration: '1000',
                    });
                }
            });
        },
        onFirstImported() {
            importIE(this.uploadForm).then(resp => {
                if (resp.code === 1) {
                    this.resultForm = resp.data;

                    this.dialogTable = true;
                    this.$refs.multipleTable.toggleAllSelection();
                    this.firstImportedBtn = false;
                } else {
                    console.log("this.uploadForm----erorr");
                }
            });
        },
        onImported() {
            // this.uploadForm.impData = this.resultForm.preResult;
            this.uploadForm.impData = this.multipleSelection;
            importIE(this.uploadForm).then(resp => {
                if (resp.code === 1) {
                    this.resultForm = resp.data;
                    // console.log("this.uploadForm----erorr", this.resultForm);
                    this.dialogTable = true;

                } else {
                    console.log("this.uploadForm----erorr");
                }
            });
        },

        changeAgentContent() {
            document.getElementById("inputFileAgent").value = document.getElementById("file").value;
        },


        handleFileUpload(event) {

            document.getElementById("inputFileAgent").value = document.getElementById("wechatFile").value;
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const buf = new Uint8Array(data);
                const isUtf8File = isUtf8(buf)
                const workbook = XLSX.read(isUtf8File ? data : cptable.utils.decode(936, buf), {
                    type: isUtf8File ? "array" : 'string',
                    cellNF: true,
                    cellDates: true,
                    // cellText: false
                });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                Object.values(worksheet).forEach(item => {
                    if (item.t === "n") {
                        item.t = 's'
                        item.v = item.w
                    }
                })
                this.jsonData = XLSX.utils.sheet_to_json(worksheet
                    , {
                        range: 16
                    }
                );
                const dataArray = [];
                this.jsonData.forEach((item) => {
                    const time = item['交易时间'];
                    const data = {
                        createDate: this.formatDate(time),
                        // createDate: time,
                        orderNo: item['交易单号'], //交易单号
                        name: item['交易对方'] + item['商品'],  //交易对方 +  商品
                        amount: item['金额(元)'].substr(1),
                        type: this.type.getFieldByLabel(item['收/支']),   //0为支出，1为收入
                        comment: item['交易对方'] + item['商品'],
                        payWay: item['支付方式'],
                        orderStatus: item['当前状态'],
                        msg: '',
                    }
                    dataArray.push(data);
                })
                this.uploadForm.impData = dataArray
            };
            reader.readAsArrayBuffer(file);

            console.log('wechat-------', this.uploadForm)

            // debugger
            // console.log('importIE(this.uploadForm).then(resp => {',this.uploadForm)

        },

        handleZFBFileUpload(event) {
            document.getElementById("inputFileAgent").value = document.getElementById("zfbFile").value;

            const file = event.target.files[0];

            const reader = new FileReader();

            reader.onload = (e) => {
                const data = e.target.result;
                const buf = new Uint8Array(data);
                const isUtf8File = isUtf8(buf)
                const workbook = XLSX.read(isUtf8File ? data : cptable.utils.decode(936, buf), {
                    type: isUtf8File ? "array" : 'string',
                    cellNF: true,
                    cellDates: true,
                    // cellText: false
                });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                Object.values(worksheet).forEach(item => {
                    if (item.t === "n") {
                        item.t = 's'
                        item.v = item.w
                    }
                })
                this.jsonData = XLSX.utils.sheet_to_json(worksheet
                    , {
                        range: 24  // 指定起始行（忽略第1-23行）
                    }
                );

                const dataArray = [];
                this.jsonData.forEach((item) => {

                    let ieType= '';
                    if(item['收/付款方式'] != '他人代付账户'){
                        if((item['收/支'] == '不计收支') && (item['交易状态'] == '退款成功')){
                            ieType='INCOME'
                        }else {
                            ieType=this.type.getFieldByLabel(item['收/支']);
                        }
                        const time = item['交易时间'];
                        const data = {
                            createDate: this.formatDate(time),
                            orderNo: item['交易订单号'], //交易单号
                            name: item['交易分类'] + item['商品说明'],  //交易对方 +  商品
                            amount: item['金额'],
                            impCateName: item['交易分类'],
                            type: ieType,   //0为支出，1为收入
                            comment: item['交易分类'] + item['商品说明'],
                            payWay: item['收/付款方式'],
                            orderStatus: item['交易状态'],
                            msg: '',
                        }
                        dataArray.push(data);
                    }
                })
                this.uploadForm.impData = dataArray

            };
            reader.readAsArrayBuffer(file);

            // console.log('zfb-------',this.uploadForm)

            // importIE(this.uploadForm).then(resp => {
            //     if (resp.code === 1) {
            //         this.resultForm = resp.data;
            //         this.dialogTable = true;
            //     } else {
            //         console.log("this.uploadForm----erorr");
            //     }
            // });
        },


        formatDate(xdate) {
            // console.log("date.getFullYear()",xdate)
            var date = new Date(Date.parse(xdate));
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },

        sendDataToServer() {
            // const jsonStr = JSON.stringify(this.jsonData);
            // console.log('jsonData', jsonStr)
        },


        clickNav(item, index) {
            this.$store.commit("SET_isActiveIndex",item.path)
            if (this.$route.path !== item.path && item.path === '/product/retireplan') {
                hasOfficialPlan().then(resp => {
                    console.log(resp.data);
                    if (resp.data === true) {
                        this.$router.push(item.path);
                    } else {
                        this.$router.push('/product/retireplanfirstuse');
                    }

                });
            } else if (this.$route.path !== item.path && !(this.$route.path === '/product/overview' && (item.path === '/product'))) {
                this.$router.push(item.path);
            }
        }
        ,
        logout() {
            const token = Cookies.get('satoken');
            // console.log(token)
            logout(token).then(resp => {
                console.log(resp)
                if (resp.code === 1) {
                    Cookies.remove('satoken')
                    this.$message({
                        message: '退出成功',
                        type: 'success',
                        duration: '500',
                    });
                    this.$router.push("/");
                    // this.$router.push("/");
                } else {
                    this.$message({message: '退出失败'});
                }
            })

        }
    }
}
</script>

<style scoped>

.navWrapper {
    padding-left: 3%;
    width: 93%;
}

/*顶部导航*/
/*头部，包括导航页,用户中通知,消息和logo*/
.header {
    height: 60px;
    background-color: white;
    padding: 10px 0;
    /*border-bottom: 1px solid #DBDBDB;*/
    /*box-shadow: 2px 2px 5px 5px rgba(0, 0, 0, 0.1);*/

    /*border-bottom: 1px solid #DBDBDB;*/
}

.nav {
    /*background-color: black;*/
    /*margin-left: 10px;*/
    color: #8c939d;

}

.nav li {
    float: left;
    margin-right: 36px;
    /*background-color: #55a532;*/
}

.nav li {
    float: left;
    margin-right: 36px;
    display: block;
    font-size: 16px;
    height: 60px;
    line-height: 60px;

}

.nav li:hover {
    border-bottom: 2px solid var(--blue-2);
    color: var(--blue-2);
}

.nav li.active {
    border-bottom: 2px solid var(--blue-2);
    color: var(--blue-2);
}

.user {
    float: right;
    margin-right: 1rem;
    display: flex;
    height: 60px;
    align-items: center;
}


.uploadBtn {
    margin-right: 2rem;
    font-size: 14px;
    height: 40px;
    width: 90px;
    border-radius: 10px;
    background-color: white;
    color: var(--blue-1);
    border: 2px solid var(--blue-1);
}


.fileUploadBtn {
    margin-right: 20px;
    font-size: 14px;
    height: 40px;
    width: 140px;
    border-radius: 10px;
    background-color: white;
    color: var(--blue-1);
    border: 2px solid var(--blue-1);
}

/*导入账单表单样式*/

.dialogUploadDetail {
    width: 900px;
    margin: 0 auto;
    padding-bottom: 50px;
    /*background-color: #F2F6FC;*/
}

.dialogUploadDetailTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
}

.dialogUploadDetailTitle .icon {
    font-size: 1.8rem;
}

.dialogUploadDetailTitle b {
    font-size: 1.4rem;
}

.dialogTable {
    width: 900px;
    margin: 0 auto;
    padding-bottom: 20px;
}

.el-table .success-row {
    color: rgb(27, 33, 27) !important;
}

.el-table .warning-row {
    color: #f51f1f !important;
}


</style>
