export function getThousandsNum(n = 0) {
    if ((typeof n === 'string' || typeof n === 'number') && !isNaN(n)) {
        return parseFloat(n)
            .toFixed(2)
            // .toLocaleString('en-US')
            .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
            // .replace('.00', '')

    }
    return n
}

