import axios from 'axios'
import {MessageBox, Message} from 'element-ui'
import store from "@/store";

// import store from '@/store'
// import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    baseURL: '/api', // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {

        // console.log('service.interceptors.request.use==========config') // for debug

        // do something before request is sent

        // if (store.getters.token) {
        //   // let each request carry token
        //   // ['X-Token'] is a custom headers key
        //   // please modify it according to the actual situation
        //   config.headers['token'] = getToken()
        // }

        // if(store.state.token){
        //     console.log('store.state.token')
        //     console.log(store.state.token)
        //     config.headers['satoken'] = store.state.token
        // }
        return config
    },
    error => {

        // do something with request error
        return Promise.reject(error).catch(err => {
                this.$message.error(err.message);
                console.log(err);
            }
        )
    }
)

service.interceptors.response.use(
    response => {
        const res = response.data
        console.log('service.interceptors.response.use',res)
        // if the custom code is not 20000, it is judged as an error.



        if (res.code !== 1) {


            if (res.code === -100) {
                console.log(res)
                MessageBox.confirm('登陆失效，请选择重新登录', '登录失效确认', {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    store.commit('REMOVE_STORAGE',store.state.token)
                    location.reload();
                })
            }else{
                Message({
                    message: res.msg || 'Error',
                    type: 'error',
                    duration: 2 * 1000
                })

            }


            // // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            // if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
            //     // to re-login
            //     MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
            //         confirmButtonText: 'Re-Login',
            //         cancelButtonText: 'Cancel',
            //         type: 'warning'
            //     }).then(() => {
            //         store.dispatch('user/resetToken').then(() => {
            //             location.reload()
            //         })
            //     })
            // }

            // return Promise.reject(new Error(res.message || 'Error'))
            return Promise.reject(res.msg)
        } else {
            return Promise.resolve(res)
        }
        // } else {
        //     return res
        // }
    },
    error => {
        console.log('err' + error) // for debug
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)


//
// // response interceptor
// service.interceptors.response.use(
//   /**
//    * If you want to get http information such as headers or status
//    * Please return  response => response
//   */
//
//   /**
//    * Determine the request status by custom code
//    * Here is just an example
//    * You can also judge the status by HTTP Status Code
//    */
//   response => {
//     // const res = response.data
//     const res = response.data;
//     // console.log(res);
//     // return res
//
//     // if (res.errorCode === undefined) {
//     if (res.code === 1) {
//       return res
//     }
//     // if (res.success === true) {
//     //   return res
//     // }
//     // if (res.errorCode === 4001 || res.errorCode === 4002) {
//     // if (res.errorCode === -100) {
//     if (res.code === -100) {
//       MessageBox.confirm('登陆失效，请选择重新登录', '登录失效确认', {
//         confirmButtonText: '重新登录',
//         cancelButtonText: '取消',
//         type: 'warning'
//       }).then(() => {
//           console.log('store.commit(\'$_removeStorage\',store.state.token)')
//           store.commit('$_removeStorage',store.state.token)
//           location.reload();
//           // this.$router.push('/login');
//         // store.dispatch('user/resetToken').then(() => {
//         //   location.reload()
//         // })
//       })
//     } else {
//       Message({
//         message: res.errorMessage || 'Error',
//         type: 'error',
//         duration: 5 * 1000
//       })
//       return Promise.reject(new Error(res.errorMessage || 'Error'))
//
//     }
//   },
//   error => {
//     console.log('err' + error) // for debug
//     Message({
//       message: error.message,
//       type: 'error',
//       duration: 5 * 1000
//     })
//     return Promise.reject(error)
//   }
// )

export default service
