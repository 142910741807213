import service from "@/utils/request";

export const getInvestDetails = () => {
    return service.get("/investDetails/")
}

export const getSymbol = (searchKey,symbolType) => {
    return service.get("/symbol",{params: {searchKey,symbolType}})
}

export const getSymbolPrice = (symbol) => {
    return service.get("/symbol/quotation",{params: {symbol}})
}

export const addInvest = (invest) => {
    return service.post("/investDetails/addInvest", invest)
}

export const deleteInvest = (id) => {
    return service.delete("/investDetails/" + id)
}

export const dailyProfit = () => {
    return service.get("/investProfit/dailyProfit/")
}

export const last7Days = () => {
    return service.get("/investProfit/last7Days")
}


export const sellInvest = (invest) => {
    return service.post("/investDetails/saleInvest", invest)
}


export const buyInvest = (invest) => {
    return service.post("/investDetails/buyInvest", invest)
}


export const getInvestmentHistory = () => {
    return service.get("/investSale/myList")
}
