import service from "@/utils/request";

export const chartnetworth = (lastDay) => {
    return service.get("/dashboard/netAssets",{params: {lastDay}})
}


export const chartie = (lastMonth) => {
    return service.get("/dashboard/incomeAndExpense",{params: {lastMonth}})
}
