import service from "@/utils/request";

export const login=(user) => {
    return service.post("/user/login",user)
}

export const logout=(token) => {
    return service.post("/user/logout",token)
}

export const isFirstLogin=(token) => {
    return service.post("/user/isFirst",token)
}


export const LoginStatus = (t) => {
    return service.get("/user/checkLoginStatus",{params: {t}})
}

export const pcLoginUrl = () => {
    return service.get("/user/pcLoginUrl")
}
