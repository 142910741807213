import Vue from "vue"
import Vuex  from "vuex"
import Cookies from "js-cookie";
// import tab from "@/store/tab";

Vue.use(Vuex)

// export default new Vuex.Store({
//     modules:{
//         tab
//     }
// })

const key = 'satoken'
const store = new Vuex.Store({
    state () {
        return {
            token: Cookies.get(key)?Cookies.get(key):'',
            dataRefresh: false,
            tableData: [],
            chartData: [],
            isActive: '/product/overview',
        }
    },
    getters: {
        getSortage(state) {
            if(!state.token){
                state.token =Cookies.get(key)
                // state.token =JSON.parse(localStorage.getItem(key))
            }
            return state.token
        }
    },
    mutations: {
        SET_STORAGE(state,value){
            state.token = value;
            Cookies.set(key,value)
            // localStorage.setItem(key,JSON.stringify(value))
        },
        REMOVE_STORAGE(state){
            state.token = null;
            Cookies.remove(key)
            // localStorage.removeItem(key)
        },

        // sideAside删除数据调用，做更新
        dataRefreshMethod(state){
            state.dataRefresh = !state.dataRefresh
        },

        // sideAside修改或新增数据调用，做更新
        SET_TABLE_DATA(state, payload) {
            state.tableData = payload;
        },

        // sideAside修改或新增数据调用，做更新
        SET_CHART_DATA(state, payload) {
            state.chartData = payload;
        },

        // 导航栏选中位置，默认为总览
        SET_isActiveIndex(state, payload) {
            state.isActive = payload;
        },

    }
})
export default store;
