import Vue from 'vue';
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import OverView from "@/views/OverView.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/', name: 'homepage', component: () => import('../views/Homepage.vue')
    }, {
        // path: '/', component: Home, name: 'home', redirect: 'overview', //重定向
        path: '/product/', component: Home, name: 'product', redirect: '/product/overview', //重定向
        children: [{path: 'overview', component: OverView}, {
            path: 'networth', component: () => import('../views/NetWorth.vue')
        }, {
            path: 'incomeexpense', component: () => import('../views/IncomeExpense.vue')
        }, {
            path: 'retireplan', component: () => import('../views/RetirePlan.vue')
        }, {
            path: 'investment', component: () => import('../views/Investment.vue')
        }, {
            path: 'retireplanfirstuse', component: () => import('../views/RetirePlanFirstUse.vue')
        },],
    }, {
        path: '/login', name: 'login', component: () => import('../views/LoginWX.vue'),
    }, {
        path: '/register', name: 'register', component: () => import('../views/Register.vue'),
    }, {
        path: '/find', name: 'find', component: () => import('../views/FindPswd.vue'),
    }, {
        path: '/firstuse', name: 'firstuse', component: () => import('../views/FirstUse.vue')
    }, {
        path: '/section', name: 'section', component: () => import('../views/Section.vue')
    }, {
        path: '/agreement', name: 'agreement', component: () => import('../views/ServiceContract.vue')
    }, {
        path: '/about', name: 'about', component: () => import('../views/About.vue')
    }, {
        path: '/contact', name: 'contact', component: () => import('../views/Contact.vue')
    }, {
        path: '/join', name: 'join', component: () => import('../views/JoinUs.vue')
    }, {
        path: '/price', name: 'price', component: () => import('../views/Price.vue')
    },{
        path: '/step', name: 'step', component: () => import('../views/StepFirstUse.vue')
    }, {
        path: '/firstadd', name: 'firstadd', component: () => import('../views/FirstAdd.vue'),
    },]

const router = new VueRouter({
    // require service support
    mode: 'history',
    routes // short for `routes: routes`
})

export default router

