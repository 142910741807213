import Vue from 'vue';
import App from './App.vue';
import './assets/font/iconfont.css';
import router from './router';
import store from "@/store";
import Cookies from "js-cookie";
import { getThousandsNum } from './utils/common'

import {hidden} from "@/utils/hidden";

// import * as echarts from "echarts";
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart,LineChart,PieChart, TreemapChart} from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
} from 'echarts/components';
// 标签自动布局、全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';

// 注册必须的组件
echarts.use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    BarChart,
    LineChart,
    PieChart,
    TreemapChart,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer
]);

Vue.prototype.$echarts = echarts// 绑定echarts

// import ElementUI from 'element-ui';
// Vue.use(ElementUI);
// import 'element-ui/lib/theme-chalk/index.css';

import {
    Dialog,
    Autocomplete,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Input,
    Radio,
    RadioGroup,
    RadioButton,
    Select,
    Option,
    Button,
    Table,
    TableColumn,
    DatePicker,
    Form,
    FormItem,
    Slider,
    Progress,
    Steps,
    Step,
    Message,
    Switch,
} from 'element-ui';
import {isFirstLogin} from "@/api/login";

Vue.use(Progress);
Vue.use(Dialog);
Vue.use(Autocomplete);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Input);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Select);
Vue.use(Option);
Vue.use(Button);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Slider);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Switch);

Vue.prototype.$message = Message;






Vue.config.productionTip = false;

Vue.filter('formatPrice', getThousandsNum)

Vue.filter('hideAmount', hidden)


// router.beforeEach((to, from, next) => {
//     const token = Cookies.get('satoken');
//     // debugger;
//     // if (!token && (to.name !== 'login' || to.name !== 'register')) {
//     if (!token && to.name === 'register') {
//         next()
//     } else if (!token && to.name !== 'login') {
//         // debugger;
//         next({name: 'login'})
//     } else if(token && to.name === 'firstuse'){
//         next()
//     } else if (token && to.name === 'login') {
//         // debugger;
//         next({name: 'home'});
//     } else {
//         // debugger;
//         if (Cookies.get('isFirstLogin') === 'true') {
//             // console.log('isFirstLogin')
//             Cookies.remove('isFirstLogin')
//             next({name: 'firstuse'});
//         } else {
//             next();
//         }
//         // next();
//     }
// });

router.beforeEach((to, from, next) => {
    const token = Cookies.get('satoken');
    if (token && to.name === 'login') {
        isFirstLogin(token).then((resp) => {
            if (resp.data === true) {
                next({name: 'step'})
            } else{
                // this.$router.push("/product/");
                to.query.hold !== 'login' ? next({ name: 'product' }) : next()
            }
        })

    }else if (!token && to.name !== 'login' && to.name !== 'register'
        && to.name !== 'find' && to.name !== 'homepage'
        && to.name !== 'agreement' && to.name !== 'about'
        && to.name !== 'contact' && to.name !== 'join'
        && to.name !== 'price') {
        // next({name: 'login'})
        next({name: 'homepage'})
    }else {
        next();
    }
});


// 请求isFirstLogin接口,不是第一次登入调转到firstuse界面
// debugger
//  isFirstLogin(token).then((resp)=>{
//     if (resp.data === true) {
//         next({name:'firstuse'});
//     }else{
//         next();
//     }
// });

/*        if(Cookies.get('isFirstLogin')){
            next({name:'firstuse'});
        }else {
            next();
        }*/

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
    // mounted() {
    //     document.dispatchEvent(new Event('render-event'))
    // },
}).$mount('#app')

